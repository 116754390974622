import React from 'react';

const ProductContent = ({ Field, ErrorMessage }) => {

  return (
    <div className='field'>
      <label className='label'>محتوى المنتج</label>
      <div className='control'>
        <Field as='textarea' name='content' className='textarea' />
        <ErrorMessage name='content' />
      </div>
    </div>
  )
}

export default ProductContent;
