import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { toast } from 'bulma-toast';
import server from '../../../../utilities/server';
import ErrorMessage from '../../../../common/components/ErrorMessage';

const UpdateUser = ({ modal, setModal, refetch, data }) => {
	const [loading, setLoading] = React.useState(false);

	const [userData, setUserData] = React.useState({
		name: data.name,
		email: data.email,
		password: '',
		role: data.role,
	});
	React.useEffect(() => {
		setUserData({
			name: data.name,
			email: data.email,
			password: '',
			role: data.role,
		})
	}, [data]);

	const validate = Yup.object({
		name: Yup.string()
			.min(4, 'لابد أن يتكون الاسم من أربعة حروف فأكثر')
			.required('لابد من اضافة اسم العضو'),
		password: Yup.string()
			.notRequired()
			.test(
				'password',
				'لابد أن تتكون كلمة المرور من ستة أحرف فأكثر',
				(value) => {
					if (!!value) {
						const schema = Yup.string().min(6);
						return schema.isValidSync(value);
					}
					return true;
				}
			),
		role: Yup.string()
			.required('لابد من اختيار الدور'),
	});

	return (
		<div className={`modal ${modal ? 'is-active' : ''}`}>
			<div className='modal-background' onClick={() => setModal({ id: 0, name: '', email: '', role: '', })}></div>
			<Formik
				initialValues={userData}
				enableReinitialize
				validationSchema={validate}
				onSubmit={(values, { resetForm }) => {
					setLoading(true);
					const user = {};
					user['name'] = values.name;
					if (values.password !== '') user['password'] = values.password;
					if (values.role !== 'super') user['role'] = values.role;
					server({
						method: 'put',
						path: `users/${data.id}`,
						data: user,
					}).then((_) => {
						toast({ type: 'is-success', position: 'center', message: 'تمت تحديث العضو بنجاح' });
						refetch();
						setModal({ id: 0, name: '', email: '', role: '', });
						setLoading(false);
						resetForm();
					}).catch((_) => {
						console.log(_.response)
						toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء تحديث العضو' });
						setModal({ id: 0, name: '', email: '', role: '', });
						setLoading(false);
					});
				}}
			>
				<Form>
					<div className='modal-card'>
						<header className='modal-card-head'>
							<p className='modal-card-title'>تحديث العضو {userData.name}</p>
							<a className='delete' aria-label='close' onClick={() => setModal({ id: 0, name: '', email: '', role: '', })}></a>
						</header>
						<div className='modal-card-body'>
							<div className='field'>
								<label className='label'>اسم العضو</label>
								<div className='control'>
									<Field name='name' className='input mb-1' autoComplete='off' />
									<ErrorMessage name='name' />
								</div>
							</div>
							<div className='field'>
								<label className='label'>الايميل</label>
								<div className='control'>
									<Field type='email' name='email' className='input mb-1' disabled />
								</div>
							</div>
							<div className='field'>
								<label className='label'>كلمة المرور</label>
								<div className='control'>
									<Field type='password' name='password' className='input mb-1' autoComplete='off' />
									<ErrorMessage name='password' />
								</div>
							</div>
							{
								userData.role !== 'super'
									?
									<div className='field'>
										<label className='label'>الدور</label>
										<div className='control'>
											<Field as='select' name='role' className='select mb-1 is-fullwidth'>
												<option></option>
												<option value='admin'>اداري</option>
												<option value='user'>كاتب</option>
												<option value='client'>عميل</option>
											</Field>
											<ErrorMessage name='role' />
										</div>
									</div>
									: null
							}
						</div>
						<footer className='modal-card-foot'>
							<a type='button' className='button' data-bs-dismiss='modal' onClick={() => setModal({ id: 0, name: '', email: '', role: '', })}>اغلاق</a>
							<button className='button is-link' type='submit' disabled={loading}>تحديث</button>
						</footer>
					</div>
				</Form>
			</Formik>
		</div>
	)
};

export default UpdateUser;
