import React from 'react';
import { useQuery } from 'react-query';
import Header from '../../common/Header';
import server from '../../utilities/server';
import CategoryIcon from './components/categories/CategoryIcon';
import CategoryTheme from './components/categories/CategoryTheme';
import CategoryImage from './components/categories/CategoryImage';
import NewCategory from './components/categories/NewCategory';
import UpdateCategory from './components/categories/UpdateCategory';
import HideCategory from './components/categories/HideCategory';
import DeleteCategory from './components/categories/DeleteCategory';

const AdminCategories = () => {
  
  const [categoryData, setCategoryData] = React.useState({ id: 0, name: '' });

  const [iconModal, setIconModal] = React.useState(false);
  const _iconModal = (data) => {
    setCategoryData(data);
    setIconModal(!iconModal);
  }
  const [imageModal, setImageModal] = React.useState(false);
  const _imageModal = (data) => {
    setCategoryData(data);
    setImageModal(!imageModal);
  }
  const [themeModal, setThemeModal] = React.useState(false);
  const _themeModal = (data) => {
    setCategoryData(data);
    setThemeModal(!themeModal);
  }

  const [addModal, setAddModal] = React.useState(false);
  const _addModal = () => {
    setAddModal(!addModal);
  }

  const [updateModal, setUpdateModal] = React.useState(false);
  const _updateModal = (data) => {
    setCategoryData(data);
    setUpdateModal(!updateModal);
  }
  
  const [hideModal, setHideModal] = React.useState(false);
  const _hideModal = (data) => {
    setCategoryData(data);
    setHideModal(!hideModal);
  }
  const [deleteModal, setDeleteModal] = React.useState(false);
  const _deleteModal = (data) => {
    setCategoryData(data);
    setDeleteModal(!deleteModal);
  }

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['categories'], () => {
    return server({ path: 'categories', method: 'get' });
  });

  if(isLoading) {
    return <div className='container'>تحميل...</div>
  }

  if(isLoadingError || isError) {
    return <div className='container'>خطأ...</div>
  }

  const AddNew = () => {
    return (
      <div className='is-pulled-left'>
        <div className='field is-grouped'>
          <div className='control'>
            <button className='button is-ghost' onClick={ _addModal }>اضافة</button>
          </div>
          <div className='control'>
            <button className='button is-ghost' onClick={ refetch }>تحديث</button>
          </div>
        </div>
      </div>
    )
  }

  const Actions = ({ data }) => {
    return (
      <td width='30'>
        <div className='is-pulled-left'>
          <div className='field is-grouped'>
            <div className='control'>
              <button className='button is-text is-small' onClick={ () => _iconModal(data) }>الأيقونة</button>
            </div>
            <div className='control'>
              <button className='button is-text is-small' onClick={ () => _imageModal(data) }>الصورة</button>
            </div>
            <div className='control'>
              <button className='button is-text is-small' onClick={ () => _themeModal(data) }>القالب</button>
            </div>
            <div className='control'>
              <button className='button is-ghost is-small' onClick={ () => _updateModal(data) }>تحديث</button>
            </div>
            <div className='control'>
              <button className='button is-info is-light is-small' onClick={ () => _hideModal(data) }>{ data.status === 'draft' ? 'نشر' : 'اخفاء' }</button>
            </div>
            <div className='control'>
              <button className='button is-light is-danger is-small' onClick={ () => _deleteModal(data) }>حذف</button>
            </div>
          </div>
        </div>
      </td>
    )
  }

  return (
    <div>
      <Header type='admin'>
        <AddNew />
      </Header>
      <div className='container'>
        <NewCategory modal={ addModal } setModal={ _addModal } refetch={ refetch } />
        <CategoryImage modal={ imageModal } setModal={ _imageModal } refetch={ refetch } category={ categoryData } />
        <CategoryIcon modal={ iconModal } setModal={ _iconModal } refetch={ refetch } category={ categoryData } />
        <CategoryTheme modal={ themeModal } setModal={ _themeModal } refetch={ refetch } category={ categoryData } />
        <UpdateCategory modal={ updateModal } setModal={ _updateModal } refetch={ refetch } data={ categoryData } />
        <HideCategory modal={ hideModal } setModal={ _hideModal } refetch={ refetch } data={ categoryData } />
        <DeleteCategory modal={ deleteModal } setModal={ _deleteModal } refetch={ refetch } data={ categoryData } />
        <div className='table-container'>
          <table className='table is-striped is-hoverable is-fullwidth'>
            <thead>
              <tr>
                <th width='10'>#</th>
                <th>القسم</th>
                <th className='has-text-centered'>الإجراء</th>
              </tr>
            </thead>
            <tbody>
              {
                data.data.map((category, index) => {
                  return (
                    <tr key={index}>
                      <th>{ index + 1 }</th>
                      <td>{ category.name } - 
                        <small className='is-size-7'> الحالة: { category.status === 'draft' ? 'مخفي' : 'منشور' }</small>,
                        <small className='is-size-7'> منتجات: { category._count.product }</small> 
                      </td>
                      <Actions data={ category } />
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AdminCategories;
