import { Routes, Route } from "react-router-dom";

/**
 * Main pages
 */
import Home from "../website/Home";
import Tabea from "../website/main/Tabea";
import Products from "../website/main/Products";
import Services from "../website/main/Services";
import Partners from "../website/main/Partners";
import Clients from "../website/main/Clients";
import Registeration from "../website/main/Registeration";
import About from "../website/main/About";
import Join from "../website/main/Join";
import Principles from "../website/main/Principles";
import Terms from "../website/main/Terms";
import Refund from "../website/main/Refund";
import FAQ from "../website/main/FAQ";
import Contact from "../website/main/Contact";

/**
 * Sections pages
 */
import Writing from "../website/sections/Writing";
import Listening from "../website/sections/Listening";
import Visual from "../website/sections/Visual";
import Digital from "../website/sections/Digital";
import Personal from "../website/sections/Personal";
import Training from "../website/sections/Training";
import Advertising from "../website/sections/Advertising";
import Business from "../website/sections/Business";

/**
 * Windoes pages
 */
import Gifts from "../website/windows/Gifts";
import Cards from "../website/windows/Cards";
import Tens from "../website/windows/Tens";
import Messages from "../website/windows/Messages";
import WTabea from "../website/windows/Tabea";
import Ideas from "../website/windows/Ideas";

/**
 * Common login page
 */
import Login from "./Login";

/**
 * Admin pages
 */
import AdminRouter from "../cpanels/admin/Main";
// import AdminHome from '../cpanels/admin/Home';
import AdminCategories from "../cpanels/admin/Categories";
import AdminProducts from "../cpanels/admin/Products";
import AdminAssign from "../cpanels/admin/Assign";
import AdminOrders from "../cpanels/admin/Orders";
import AdminUsers from "../cpanels/admin/Users";

/**
 * User pages
 */
import UserRouter from "../cpanels/user/Main";
// import UserHome from '../cpanels/user/Home';
import UserJobs from "../cpanels/user/Jobs";
import UserSignup from "../cpanels/user/Signup";

/**
 * Cleint pages
 */
import ClientRouter from "../cpanels/client/Main";
import ClientHome from "../cpanels/client/Home";
import ClientCategories from "../cpanels/client/Categories";
import ClientProducts from "../cpanels/client/Products";
import CleintSignup from "../cpanels/client/Signup";
import Invoices from "../cpanels/client/Invoices";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="/" element={<ClientHome />} />
        <Route path="invoices/:id" element={<Invoices />} />
        <Route path="tabea" element={<Tabea />} />
        <Route path="products" element={<Products />} />
        <Route path="services" element={<Services />} />
        <Route path="partners" element={<Partners />} />
        <Route path="clients" element={<Clients />} />
        <Route path="registeration" element={<Registeration />} />
        <Route path="about" element={<About />} />
        <Route path="join" element={<Join />} />
        <Route path="principles" element={<Principles />} />
        <Route path="terms" element={<Terms />} />
        <Route path="refund" element={<Refund />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="contact" element={<Contact />} />
      </Route>
      <Route path="/sections" element={<Home />}>
        <Route path="" element={<Writing />} />
        <Route path="listening" element={<Listening />} />
        <Route path="visual" element={<Visual />} />
        <Route path="digital" element={<Digital />} />
        <Route path="personal" element={<Personal />} />
        <Route path="training" element={<Training />} />
        <Route path="advertising" element={<Advertising />} />
        <Route path="business" element={<Business />} />
      </Route>
      <Route path="/windows" element={<Home />}>
        <Route path="" element={<Gifts />} />
        <Route path="cards" element={<Cards />} />
        <Route path="tens" element={<Tens />} />
        <Route path="messages" element={<Messages />} />
        <Route path="tabea" element={<WTabea />} />
        <Route path="ideas" element={<Ideas />} />
      </Route>
      <Route path="/admin" element={<AdminRouter />}>
        <Route path="" element={<AdminCategories />} />
        <Route path="categories" element={<AdminCategories />} />
        <Route path="products" element={<AdminProducts />} />
        <Route path="assign" element={<AdminAssign />} />
        <Route path="orders" element={<AdminOrders />} />
        <Route path="users" element={<AdminUsers />} />
      </Route>
      <Route path="/user" element={<UserRouter />}>
        <Route path="" element={<UserJobs />} />
        <Route path="jobs" element={<UserJobs />} />
      </Route>
      <Route path="/client" element={<ClientRouter />}>
        <Route index element={<CleintSignup />} />
        <Route path="categories" element={<ClientCategories />} />
        <Route path="products/:category" element={<ClientProducts />} />
        <Route path="signup" element={<CleintSignup />} />
      </Route>
      <Route path="/admin/login" element={<Login />} />
      <Route path="/user/login" element={<Login />} />
      <Route path="/user/signup" element={<UserSignup />} />
      {/* <Route path="*" element={<h1>not found</h1>} /> */}
    </Routes>
  );
};

export default Router;
