import React from 'react';
import axios from 'axios';

export default ({ file }) => {
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    async function getImage() {
      if (file) {
        const iconData = await axios.get('media/category-image', { params: { name: file, }, responseType: 'blob', });
        setImage(URL.createObjectURL(iconData.data));
      }
    }
    getImage();
  }, [file]);
  if (image) return <img src={ image } />;
  return <img src={require('../../../assets/images/categories.png')}/>;
}
