
const dateFormat = (date) => {

  const getDate = date.split('T')[0];
  // const year = getDate[0];
  // const month = getDate[0];
  // const day = getDate[0];
  return getDate;

}

export {
  dateFormat
}
