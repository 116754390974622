import { NotifyContext, NotifyAction } from "./common/Context";
import Menu from "./common/Menu";
import Footer from "./common/Footer";
import Router from "./common/Router";
import ScrollToTop from "./common/components/ScrollToTop";

function App() {
  const { notification, setNotification } = NotifyAction();
  return (
    <NotifyContext.Provider
      value={{ state: notification, action: setNotification }}
    >
      <Menu />
      <ScrollToTop />
      <Router />
      <Footer />
    </NotifyContext.Provider>
  );
}

export default App;
