import axios from "axios";

const Server = async (config) => {
  const role = localStorage.getItem("role");
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${
    process.env.NODE_ENV === "development"
      ? "https://server.tabea1.com"
      : "https://server.tabea1.com"
  }/${role ? role : "client"}`;

  const path = config.path || "";
  const method = config.method || "get";
  let headers = {};
  if (config.contentType) {
    headers = { "Content-Type": config.contentType };
  } else {
    headers = { "Content-Type": "application/json" };
  }
  if (config.recaptcha) {
    headers = { ...headers, "x-xbuffer-recaptcha": config.recaptcha };
  }
  const sendRequest = {
    headers: {
      ...headers,
    },
    url: path,
    method: method,
  };
  if (method === "get") {
    sendRequest.params = config.data;
  } else {
    sendRequest.data = config.data;
  }
  if (config.responseType) {
    sendRequest.responseType = config.responseType;
  }
  return await axios(sendRequest);
};

export default Server;
