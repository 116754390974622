import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'bulma-toast';

import server from '../../../utilities/server';
import ErrorMessage from '../../../common/components/ErrorMessage';

const Pay = ({ modal, cart, products, amount, setModal }) => {

  const [loading, setLoading] = React.useState(false);

  const cardNumber = /^3(?:[47]\d([ -]?)\d{4}(?:\1\d{4}){2}|0[0-5]\d{11}|[68]\d{12})$|^4(?:\d\d\d)?([ -]?)\d{4}(?:\2\d{4}){2}$|^6011([ -]?)\d{4}(?:\3\d{4}){2}$|^5[1-5]\d\d([ -]?)\d{4}(?:\4\d{4}){2}$|^2014\d{11}$|^2149\d{11}$|^2131\d{11}$|^1800\d{11}$|^3\d{15}$/;
  const cardName = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i;
  const validate = Yup.object({
    payment: Yup.string()
      .required('لابد من اختيار وسيلة الدفع'),
    ccard: Yup.string()
      .trim()
      .required('لابد من كتابة رقم البطاقة')
      .matches(cardNumber, 'رقم بطاقة الائتمان غير صحيح'),
    cname: Yup.string()
      .trim()
      .required('لابد من كتابة الاسم')
      .matches(cardName, 'لابد من ادخال الاسم بشكل صحيح'),
    cyear: Yup.number()
      .required('لابد من اختيار سنة الانتهاء'),
    cmonth: Yup.number()
      .required('لابد من اختيار شهر الانتهاء'),
    ccvc: Yup.string()
      .matches(/^[0-9]{3,4}$/i, 'لابد أن يحتوي على ثلاثة أو أربعة أرقام')
      .required('لابد من اختيار الرقم السري'),
  });

  return (
    <div id='new-category' className={`modal ${modal ? 'is-active' : ''}`}>
      <div className='modal-background' onClick={() => setModal(false)}></div>
      <Formik
        initialValues={{
          amount: '',
          products: [],
          payment: '',
          ccard: '',
          cname: '',
          cyear: '',
          cmonth: '',
          ccvc: '',
        }}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          values.amount = amount;
          values.products = cart;
          setLoading(true);
          server({
            method: 'post',
            path: 'orders',
            data: values,
          }).then((orderResult) => {
            console.log(orderResult.data);

            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://api.moyasar.com/v1/payments.html';

            const callback = document.createElement('input');
            callback.name = 'callback_url';
            callback.value = `${process.env.NODE_ENV === 'development' ? 'http://localhost:3003' : 'https://server.tabea1.com' }/client/orders/update/${orderResult.data.id}`;
            form.appendChild(callback);

            const moyaserKey = document.createElement('input');
            moyaserKey.name = 'publishable_api_key';
            // moyaserKey.value = 'pk_test_w6se4Vkk3UpmQwMG5YnaFR8hpYTJNHiMie15N28B'; // Testing
            moyaserKey.value = 'pk_live_oa5nFs56WzUhUNJ7toCC4g78uizJtQZG1CkjcLEf'; // Live
            form.appendChild(moyaserKey);
            
            const amount = document.createElement('input');
            amount.name = 'amount';
            amount.value = parseInt(+values.amount * 100);
            form.appendChild(amount);
            
            const sourceType = document.createElement('input');
            sourceType.name = 'source[type]';
            sourceType.value = 'creditcard'; //values.payment;
            form.appendChild(sourceType);

            const description = document.createElement('input');
            description.name = 'description';
            description.value = `شراء المنتجات: ${values.products.join(', ')}`;
            form.appendChild(description);

            const cardName = document.createElement('input');
            cardName.name = 'source[name]';
            cardName.value = values.cname;
            form.appendChild(cardName);

            const cardNumber = document.createElement('input');
            cardNumber.name = 'source[number]';
            cardNumber.value = parseInt(values.ccard);
            form.appendChild(cardNumber);

            const cardMonth = document.createElement('input');
            cardMonth.name = 'source[month]';
            cardMonth.value = parseInt(values.cmonth);
            form.appendChild(cardMonth);

            const cardYear = document.createElement('input');
            cardYear.name = 'source[year]';
            cardYear.value = parseInt(values.cyear);
            form.appendChild(cardYear);

            const cardCVC = document.createElement('input');
            cardCVC.name = 'source[cvc]';
            cardCVC.value = parseInt(values.ccvc);
            form.appendChild(cardCVC);

            document.body.appendChild(form);
            form.submit();

          }).catch((_) => {
            toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء اتمام عملية الشراء' });
            setModal();
            setLoading(false);
          });
        }}
      >
        {(props) => (
          <Form>
            <div className='modal-card'>

              <header className='modal-card-head'>
                <p className='modal-card-title'>شراء المنتجات: {products.map((product, index) => `${product.name} ${((index + 1) < products.length) ? 'و ' : ''}`)}</p>
                <p className='delete' aria-label='close' onClick={() => setModal(false)}></p>
              </header>
              <div className='modal-card-body'>
                <div className='field'>
                  <label className='label'>خيارات الدفع</label>
                </div>
                <div className='control mb-3'>
                  <label className='radio'>
                    <Field type='radio' name='payment' value='creditcard' />
                    <img src={require('../../../assets/images/mada.png')} style={{ maxWidth: '55px' }} className='mr-2' alt='Mda' />
                  </label>
                  <label className='radio'>
                    <Field type='radio' name='payment' value='mada' />
                    <img src={require('../../../assets/images/visa.png')} style={{ maxWidth: '55px' }} className='mr-2' alt='Credit Card' />
                  </label>
                  <ErrorMessage name='payment' />
                </div>
                <div className='field'>
                  <label className='label'>رقم البطاقة</label>
                  <div className='control'>
                    <Field name='ccard' className='input mb-1' autoComplete='off' />
                    <ErrorMessage name='ccard' />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>الاسم على البطاقة</label>
                  <div className='control'>
                    <Field name='cname' className='input mb-1' autoComplete='off' />
                    <ErrorMessage name='cname' />
                  </div>
                </div>
                <div className='field is-horizontal'>
                  <div className='field-body'>
                    <div className='field'>
                      <label className='label'>سنة الانتهاء</label>
                      <div className='control'>
                        <Field as='select' name='cyear' className='select mb-1 is-fullwidth'>
                          <option>yyyy</option>
                          <option value='2022'>2022</option>
                          <option value='2023'>2023</option>
                          <option value='2024'>2024</option>
                          <option value='2025'>2025</option>
                          <option value='2026'>2026</option>
                          <option value='2027'>2027</option>
                          <option value='2028'>2028</option>
                        </Field>
                        <ErrorMessage name='cyear' />
                      </div>
                    </div>
                    <div className='field'>
                      <label className='label'>شهر الانتهاء</label>
                      <div className='control'>
                        <Field as='select' name='cmonth' className='select mb-1 is-fullwidth'>
                          <option>mm</option>
                          <option value='01'>01</option>
                          <option value='02'>02</option>
                          <option value='03'>03</option>
                          <option value='04'>04</option>
                          <option value='05'>05</option>
                          <option value='06'>06</option>
                          <option value='07'>07</option>
                          <option value='08'>08</option>
                          <option value='09'>09</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                        </Field>
                        <ErrorMessage name='cmonth' />
                      </div>
                    </div>
                    <div className='field'>
                      <label className='label'>الرقم السري</label>
                      <div className='control is-expanded'>
                        <Field name='ccvc' className='input mb-1' autoComplete='off' />
                        <ErrorMessage name='ccvc' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className='modal-card-foot'>
                <p type='button' className='button' data-bs-dismiss='modal' onClick={() => setModal(false)}>اغلاق</p>
                <button className='button is-link' type='submit' disabled={loading}>الدفع</button>
              </footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Pay;
