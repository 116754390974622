import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { toast } from 'bulma-toast';

import server from '../../../../utilities/server';
import ErrorMessage from '../../../../common/components/ErrorMessage';

const UpdateCategory = ({ modal, setModal, refetch, data }) => {
	const [loading, setLoading] = React.useState(false);

	const [categoryData, setCategoryData] = React.useState({
		name: data.name,
	});
	React.useEffect(() => {
		if(data.id) {
			setCategoryData({
				name: data.name,
			});
		}
	}, [data]);

	const validate = Yup.object({
		name: Yup.string()
			.min(4, 'لابد أن يتكون الاسم من أربعة حروف فأكثر')
			.required('لابد من اضافة اسم القسم'),
	});

	return (
		<div className={`modal ${modal ? 'is-active' : ''}`}>
			<div className='modal-background' onClick={() => setModal({ id: 0, name: null })}></div>
			<Formik
				initialValues={categoryData}
				enableReinitialize
				validationSchema={validate}
				onSubmit={(values, { resetForm }) => {
					
					setLoading(true);
					server({
						method: 'put',
						path: `categories/${data.id}`,
						data: values,
					}).then((updateCategory) => {
						console.log(updateCategory)
						toast({ type: 'is-success', position: 'center', message: 'تمت تحديث القسم بنجاح' });
						refetch();
						setModal({ id: 0, name: null });
						setLoading(false);
						resetForm();
					}).catch((_) => {
						console.log(_.response)
						toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء تحديث القسم' });
						setModal({ id: 0, name: null });
						setLoading(false);
					});
				}}
			>
				{(props) => (
					<Form>
						<div className='modal-card'>

							<header className='modal-card-head'>
								<p className='modal-card-title'>تحديث القسم {data.name}</p>
								<a className='delete' aria-label='close' onClick={() => setModal({ id: 0, name: null })}></a>
							</header>
							<div className='modal-card-body'>
								<div className='field'>
									<label className='label'>اسم القسم</label>
									<div className='control'>
										<Field as='input' name='name' className='input mb-1' autoComplete='off' />
										<ErrorMessage name='name' />
									</div>
								</div>
							</div>
							<footer className='modal-card-foot'>
								<a type='button' className='button' data-bs-dismiss='modal' onClick={() => setModal({ id: 0, name: null })}>اغلاق</a>
								<button className='button is-link' type='submit' disabled={loading}>حفظ</button>
							</footer>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
};

export default UpdateCategory;
