import React from 'react';

import { toast } from 'bulma-toast';
import server from '../../../../utilities/server';

const ActivateUser = ({ modal, setModal, refetch, data }) => {
	const [loading, setLoading] = React.useState(false);

	const _submit = () => {
		setLoading(true);
		server({
			method: 'put',
			path: `users/${data.id}/status`,
		}).then(() => {
			toast({ type: 'is-success', position: 'center', message: 'تمت تحديث حالة العضو بنجاح' });
			refetch();
			setModal({ id: 0, name: '', email: '', role: '', });
			setLoading(false);
		}).catch((_) => {
			console.log(_.response)
			toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء تحديث حالة العضو' });
			setModal({ id: 0, name: '', email: '', role: '', });
			setLoading(false);
		});
	}

	return (
		<div id='new-category' className={`modal ${ modal ? 'is-active' : '' }`}>
			<div className='modal-background' onClick={ () => setModal({ id: 0, name: '', email: '', role: '', }) }></div>
			<div className='modal-card'>
				<header className='modal-card-head'>
					<p className='modal-card-title'>{ data.status === 'pending' ? 'تنشيط' : 'تعطيل' }  { data.name }</p>
					<a className='delete' aria-label='close' onClick={ () => setModal({ id: 0, name: '', email: '', role: '', }) }></a>
				</header>
				<div className='modal-card-body'>
					<div className='notification is-warning'>
						{ data.status === 'pending' ?
							'سيتم تنشيط العضو وستظهر جميع الاضافات المرتبطة به. هل أنت متأكد من الاستمرار؟'
						: 
							'سيتم تعطيل العضو وجميع الاضافات المرتبطة به. هل أنت متأكد من الاستمرار؟'
						}
					</div>
				</div>
				<footer className='modal-card-foot'>
					<a type='button' className='button' data-bs-dismiss='modal' onClick={ () => setModal({ id: 0, name: '', email: '', role: '', }) }>اغلاق</a>
					<button type='button' className='button is-warning' onClick={ _submit } disabled={ loading }>{ data.status === 'pending' ? 'تنشيط' : 'تعطيل' }</button>
				</footer>
			</div>
		</div>
	)
};

export default ActivateUser;
