import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { toast } from "bulma-toast";
import server from "../utilities/server";
import ErrorMessage from "./components/ErrorMessage";
import Reset from "./Reset";

const CleintLogin = () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const validate = Yup.object({
    email: Yup.string()
      .email("الايميل غير مكتوب بشكل صحيح")
      .required("الايميل مطلوب"),
    password: Yup.string()
      .min(6, "كلمة المرور لابد أن تحتوى على ٦ حروف فأكثر")
      .required("كلمة المرور مطلوبة"),
  });

  const [passwordModal, setPasswordModal] = React.useState(false);
  const _password = () => {
    setPasswordModal(true);
  };

  return (
    <>
      <div className="home-thaaa"></div>
      <div className="hero is-large">
        <div className="hero-body">
          <div className="columns is-mobile is-centered">
            <div className="column is-half">
              <h1 className="title">تسجيل دخول</h1>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validate}
                onSubmit={(values) => {
                  setLoading(true);
                  server({
                    method: "post",
                    path: "auth/login",
                    data: values,
                  })
                    .then((loginResult) => {
                      console.log(
                        "🚀 ~ file: Login.js:51 ~ .then ~ loginResult:",
                        loginResult
                      );
                      setLoading(false);
                      try {
                        let { id, email, role } = loginResult.data;

                        toast({
                          type: "is-success",
                          position: "center",
                          message: "تم تسجيل الدخول بنجاح",
                        });
                        if (
                          ["super", "admin", "user", "client"].includes(
                            role.trim()
                          )
                        ) {
                          localStorage.setItem("logged", "true");
                          localStorage.setItem("user", id);
                          localStorage.setItem("email", email);
                          localStorage.setItem("role", role);
                          if (role === "super") role = "admin";
                          if (role === "client") role = "client/categories";
                          navigate(`/${role}`);
                        } else {
                          navigate("/");
                        }
                      } catch (_) {
                        toast({
                          type: "is-danger",
                          position: "center",
                          message: "خطأ أثناء تسجيل الدخول. حاول مرة أخرى",
                        });
                        navigate("/");
                      }
                    })
                    .catch((loginError) => {
                      console.log(
                        "🚀 ~ file: Login.js:69 ~ CleintLogin ~ loginError:",
                        loginError
                      );
                      setLoading(false);
                      toast({
                        type: "is-danger",
                        position: "center",
                        message: "تسجيل دخول غير صحيح",
                      });
                      console.log(loginError);
                    });
                }}
              >
                <Form>
                  <div className="field">
                    <label className="label">البريد الالكتروني</label>
                    <div className="control">
                      <Field name="email" className="input mb-1" />
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">كلمة المرور</label>
                    <div className="control">
                      <Field
                        name="password"
                        type="password"
                        className="input mb-1"
                      />
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-link"
                        type="submit"
                        disabled={loading}
                      >
                        دخول
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
              <div className="field has-text-centered mt-5 pt-5">
                <div>
                  <a className="button is-ghost" href="/client/signup">
                    هل لديك حساب؟ أنشاء حساب جديد!
                  </a>
                </div>
                <button className="button is-ghost" onClick={_password}>
                  نسيت كلمة المرور؟
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Reset modal={passwordModal} setModal={setPasswordModal} />
    </>
  );
};

export default CleintLogin;
