import React from "react";
import { useLocation } from "react-router-dom";

import menus from "../assets/data/menus.json";

const Header = ({ type, children }) => {
  const location = useLocation();
  const paths = menus[type];
  const [title, setTitle] = React.useState();

  React.useEffect(() => {
    const path = paths.find((path) => path.link === location.pathname);
    setTitle(path.name);
  }, [location, paths]);

  return (
    <div className="pt-6">
      <div className="has-background-link-light mt-6 mb-6">
        <div className="container">
          <section className="hero">
            <div className="hero-body">
              <div className="columns">
                <div className="column">
                  <p className="title">{title}</p>
                </div>
                <div className="column">{children}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Header;
