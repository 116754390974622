import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "bulma-toast";

import server from "../../../utilities/server";
import ErrorMessage from "../../../common/components/ErrorMessage";
import { Link } from "react-router-dom";
import Reset from "../../../common/Reset";

const Login = ({ modal, setModal }) => {
  const [loading, setLoading] = React.useState(false);

  const validate = Yup.object({
    email: Yup.string()
      .email("الايميل غير مكتوب بشكل صحيح")
      .required("الايميل مطلوب"),
    password: Yup.string()
      .min(6, "كلمة المرور لابد أن تحتوى على ٦ حروف فأكثر")
      .required("كلمة المرور مطلوبة"),
  });

  const [passwordModal, setPasswordModal] = React.useState(false);
  const _password = () => {
    setPasswordModal(true);
  };

  return (
    <div id="new-category" className={`modal ${modal ? "is-active" : ""}`}>
      <div className="modal-background" onClick={() => setModal(false)}></div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          server({
            method: "post",
            path: "auth/login",
            data: values,
          })
            .then((loginResult) => {
              toast({
                type: "is-success",
                position: "center",
                message: "تم تسجيل الدخول بنجاح",
              });
              setLoading(false);
              let { id, email, role } = loginResult.data;
              localStorage.setItem("logged", "true");
              localStorage.setItem("user", id);
              localStorage.setItem("email", email);
              localStorage.setItem("role", role);
              resetForm();
              setModal();
            })
            .catch((_) => {
              toast({
                type: "is-danger",
                position: "center",
                message: "خطأ أثناء تسجيل الدخول",
              });
              resetForm();
              setLoading(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">تسجيل الدخول</p>
                <a
                  className="delete"
                  aria-label="close"
                  onClick={() => setModal(false)}
                ></a>
              </header>
              <div className="modal-card-body">
                <div className="field">
                  <label className="label">البريد الالكتروني</label>
                  <div className="control">
                    <Field
                      name="email"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">كلمة المرور</label>
                  <div className="control">
                    <Field
                      name="password"
                      type="password"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="password" />
                  </div>
                </div>
                <div className="field has-text-centered mt-5 pt-5">
                  <label className="label">
                    <Link to="/client/signup">
                      ليس لديك حساب؟ قم بإنشاء حساب جديد
                    </Link>
                  </label>
                </div>
                <div className="field has-text-centered mt-5 pt-5">
                  <button className="button is-ghost" onClick={_password}>
                    نسيت كلمة المرور؟
                  </button>
                </div>
              </div>
              <footer className="modal-card-foot">
                <a
                  type="button"
                  className="button"
                  data-bs-dismiss="modal"
                  onClick={() => setModal(false)}
                >
                  اغلاق
                </a>
                <button
                  className="button is-link"
                  type="submit"
                  disabled={loading}
                >
                  دخول
                </button>
              </footer>
            </div>
          </Form>
        )}
      </Formik>
      <Reset modal={passwordModal} setModal={setPasswordModal} />
    </div>
  );
};

export default Login;
