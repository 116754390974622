import React from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "bulma-toast";
import ClientCategories from "./Categories";

const ClientHome = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {
    if (searchParams.get("m") === "success")
      toast({
        type: "is-success",
        position: "center",
        message: "تم تفعيل حسابك بنجاح",
      });
    if (searchParams.get("m") === "error")
      toast({
        type: "is-danger",
        position: "center",
        message: "حصل خطأ أثناء تفعيل الحساب",
      });
    searchParams.delete("m");
    searchParams.toString();
    setSearchParams(searchParams);
  }, []);

  const logged = localStorage.getItem("logged");

  return (
    <>
      <div className="container pt-5 pb-5 mt-5 mb-5">
        <div className="pt-5 pb-5 mt-5 mb-5 columns">
          <div className="column is-three-fifths is-offset-one-fifth">
            <p className="title has-text-link-dark mt-4 has-text-centered">
              طابعك الأول
            </p>
            <figure className="image">
              <center>
                <img
                  src={require("../../assets/images/client_home.png")}
                  style={{ maxWidth: "650px" }}
                />
              </center>
            </figure>
            {logged ? null : (
              <div className="columns pt-5 mt-5">
                <div className="column has-text-centered">
                  <Link to="/client/signup">
                    <div className="text-center click">
                      <img
                        src={require("../../assets/images/stamp.png")}
                        width={80}
                        height={80}
                      />
                    </div>
                  </Link>
                  <p className="title has-text-link-dark fs-2 fw-bold mt-4">
                    احصل على طابعك
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ClientCategories />
    </>
  );
};

export default ClientHome;
