import React from "react";
import { Link, useLocation } from "react-router-dom";

import phrases from "../assets/data/phrases.json";

const Footer = () => {
  const [phrase, setPhrase] = React.useState(phrases.phrases);
  const location = useLocation();

  React.useEffect(() => {
    setPhrase(phrases[Math.floor(Math.random() * phrases.length)]);
  }, [location]);

  return (
    <footer
      className="footer pt-5 mt-5 is-transparent"
      style={{ position: "relative", backgroundColor: "transparent" }}
    >
      <div className="haaa"></div>
      <section className="container mb-5 content has-text-centered">
        <div className="columns text-center">
          <div className="column">
            <p className="thaaa-quote">بوصلة الانتباه</p>
            <p className="thaaa-quote-foot has-text-link-dark mb-5">{phrase}</p>
          </div>
        </div>
      </section>
      <section className="container section content mt-5">
        <div className="columns text-center mt-5">
          <div className="column mb-5">
            <p className="fs-5 fw-bolder">تعرف علينا</p>
            <Link to="/about">قصتنا</Link>
            <br />
            <Link to="/join">انضم إلينا</Link>
          </div>
          <div className="column mb-5">
            <p className="fs-5 fw-bolder">مبادئنا</p>
            <Link to="/principles">الميثاق الأخلاقي</Link>
            <br />
            <Link to="/terms">سياسة الخصوصية والاستخدام</Link>
            <br />
            <Link to="/refund">سياسة الاسترجاع</Link>
          </div>
          <div className="column mb-5">
            <div className="text-center">
              <img
                src={require("../assets/images/6abe3.png")}
                alt=""
                width={160}
                height={176}
              />
            </div>
          </div>
          <div className="column mb-5">
            <p className="fs-5 fw-bolder">تسوق</p>
            <Link to="/client">منتجاتنا</Link>
            <br />
            <Link to="/">خدماتنا</Link>
          </div>
          <div className="column mb-5">
            <p className="fs-5 fw-bolder">مساعدة</p>
            <Link to="/faq">الأسئلة الشائعة</Link>
            <br />
            <Link to="/contact">تواصل معنا</Link>
          </div>
        </div>
        <div className="columns has-text-centered">
          <div className="column mb-5">
            <a
              href="https://wa.me/966566652626"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/whats.png")} alt="" />
            </a>{" "}
            <a href="https://twitter.com/" target="_blank" rel="noreferrer">
              <img src={require("../assets/images/twitter.png")} alt="" />
            </a>{" "}
            <a
              href="https://www.snapchat.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/snap.png")} alt="" />
            </a>
            <br />
            <img src={require("../assets/images/payment.png")} alt="" />
          </div>
        </div>
        <div className="columns has-text-centered">
          <div className="column mb-5">
            <p className="has-text-link-dark">
              حقوق النشر © 2022 | جميع الحقوق محفوظة لمنصة طابع
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
