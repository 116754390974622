import React from 'react';
import { useQuery } from 'react-query';

import server from '../../../../utilities/server';

const ProductCategory = ({ Field, ErrorMessage, }) => {

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['categories'], () => {
    return server({ path: 'categories', method: 'get' });
  });

  if(isLoading) {
    return <div className='container'>تحميل الأقسام...</div>
  }

  if(isLoadingError || isError) {
    return <div className='container'>خطأ عند تحميل الأقسام</div>
  }

  return (
    <div className='field'>
      <label className='label'>القسم</label>
      <div className='control'>
        <Field as='select' name='category' className='select mb-1 is-fullwidth'>
          <option></option>
          {
            data.data.map((category, inedx) => {
              return <option value={ category.id } key={ inedx }>{ category.name }</option>
            })
          }
        </Field>
        <ErrorMessage name='category' />
      </div>
    </div>
  )
}

export default ProductCategory;
