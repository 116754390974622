import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "bulma-toast";

import server from "../utilities/server";
import ErrorMessage from "./components/ErrorMessage";

const Reset = ({ modal, setModal }) => {
  const [loading, setLoading] = React.useState(false);

  const validate = Yup.object({
    email: Yup.string()
      .email("الايميل غير مكتوب بشكل صحيح")
      .required("الايميل مطلوب"),
  });

  return (
    <div id="new-category" className={`modal ${modal ? "is-active" : ""}`}>
      <div className="modal-background" onClick={() => setModal(false)}></div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          server({
            method: "post",
            path: "auth/reset",
            data: values,
          })
            .then((_) => {
              toast({
                type: "is-success",
                position: "center",
                message: "تم تغيير كلمة المرور بنجاح وإرسالها لبريدك",
              });
              setLoading(false);
              resetForm();
              setModal();
            })
            .catch((_) => {
              toast({
                type: "is-danger",
                position: "center",
                message: "خطأ أثناء تغيير كلمة المرور",
              });
              resetForm();
              setLoading(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">تغيير كلمة المرور</p>
                <a
                  className="delete"
                  aria-label="close"
                  onClick={() => setModal(false)}
                >
                  {" "}
                </a>
              </header>
              <div className="modal-card-body">
                <div className="field">
                  <label className="label">البريد الالكتروني</label>
                  <div className="control">
                    <Field
                      name="email"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" />
                  </div>
                </div>
              </div>
              <footer className="modal-card-foot">
                <a
                  type="button"
                  className="button"
                  data-bs-dismiss="modal"
                  onClick={() => setModal(false)}
                >
                  اغلاق
                </a>
                <button
                  className="button is-link"
                  type="submit"
                  disabled={loading}
                >
                  تغيير
                </button>
              </footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Reset;
