import { Link, useLocation } from "react-router-dom";

const MenuLink = ({ routerLink, routerName }) => {
  return (
    <Link
      to={routerLink}
      className={
        useLocation().pathname === routerLink
          ? "navbar-item is-active"
          : "navbar-item"
      }
    >
      {routerName}
    </Link>
  );
};

export default MenuLink;
