import React from "react";
import axios from "axios";
import { toast } from "bulma-toast";
import server from "../../../../utilities/server";

const CategoryTheme = ({ modal, setModal, refetch, category }) => {
  const [theme, setTheme] = React.useState(null);
  const [themeData, setThemeData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const _selectTheme = (theme) => {
    setTheme(theme.target.files[0]);
  };
  const _deselectTheme = () => {
    setTheme(null);
  };

  const ShowTheme = () => {
    if (!themeData) {
      return null;
    }
    return (
      <div id="theme" className="has-text-centered p-3">
        <p className="delete" aria-label="close" onClick={_deselectTheme}></p>
        <img
          src={themeData}
          style={{ maxWidth: 150, maxHeight: 150 }}
          alt="Theme"
        />
        <br />
        {category.theme && typeof category.theme === "string" ? (
          <p
            className="button is-danger"
            onClick={_deleteTheme}
            style={loading ? { PointerEvent: "none" } : null}
          >
            حذف القالب
          </p>
        ) : null}
      </div>
    );
  };

  const _deleteTheme = async () => {
    setLoading(true);
    try {
      await axios.delete(`media/category-theme/${category.id}`, {
        params: { name: category.theme },
      });
      toast({
        type: "is-success",
        position: "center",
        message: "تم حذف القالب بنجاح",
      });
      setThemeData(null);
      setLoading(false);
    } catch (_) {
      setLoading(false);
      toast({
        type: "is-danger",
        position: "center",
        message: "خطأ أثناء حذف القالب",
      });
    }
  };

  const _submit = async () => {
    setLoading(true);
    const formData = new FormData();
    console.log(theme);
    formData.append("theme", theme);
    formData.append("resize", true);
    server({
      method: "put",
      path: `categories/theme/${category.id}`,
      contentType: "multipart/form-data",
      data: formData,
    })
      .then((updateCategory) => {
        toast({
          type: "is-success",
          position: "center",
          message: "تم رفع القالب بنجاح",
        });
        refetch();
        setModal({ id: 0, name: null });
        setTheme(null);
        setLoading(false);
      })
      .catch((_) => {
        console.log(_.response);
        toast({
          type: "is-danger",
          position: "center",
          message: "خطأ أثناء رفع القالب",
        });
        setModal({ id: 0, name: null });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    async function getTheme() {
      if (category.theme) {
        const themeData = await axios.get("media/category-image", {
          params: { name: category.theme },
          responseType: "blob",
        });
        setThemeData(URL.createObjectURL(themeData.data));
      } else {
        setThemeData(null);
      }
    }
    getTheme();
    if (theme && typeof theme === "object") {
      let reader = new FileReader();
      reader.onload = (data) => setThemeData(data.target.result);
      reader.readAsDataURL(theme);
    }
  }, [theme, modal, category.theme]);

  return (
    <div className={`modal ${modal ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => setModal({ id: 0, name: null })}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">قالب القسم {category.name}</p>
          <p
            className="delete"
            aria-label="close"
            onClick={() => setModal({ id: 0, name: null })}
          ></p>
        </header>
        <div className="modal-card-body">
          <div className="file is-boxed is-centered">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="theme"
                onChange={_selectTheme}
              />
              <span className="file-cta">
                <span className="file-label">اختر القالب</span>
              </span>
            </label>
          </div>
          <ShowTheme />
        </div>
        <footer className="modal-card-foot">
          <p
            type="button"
            className="button"
            data-bs-dismiss="modal"
            onClick={() => setModal({ id: 0, name: null })}
          >
            اغلاق
          </p>
          <button
            type="submit"
            className="button is-danger"
            onClick={_submit}
            disabled={loading}
          >
            حفظ
          </button>
        </footer>
      </div>
    </div>
  );
};

export default CategoryTheme;
