import React from "react";
import Pay from "./Pay";
import Login from "./Login";
import { Link } from "react-router-dom";

const Cart = ({ data, selected, deselect }) => {
  const [cart, setCart] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [price, setPrice] = React.useState(0);
  const [loginModal, setLoginModal] = React.useState(false);
  const [payModal, setPayModal] = React.useState(false);

  React.useEffect(() => {
    const products = data.filter((product) => {
      return selected.includes(product.id);
    });
    const total = products.reduce((previous, current) => {
      return parseInt(previous) + parseInt(current.price);
    }, 0);
    setPrice(total);
    setProducts(products);
    setCart(products.map((product) => product.id));
  }, [data, selected]);

  const [logged, setLogged] = React.useState(false);
  React.useEffect(() => {
    localStorage.getItem("logged") === "true"
      ? setLogged(true)
      : setLogged(false);
  }, [loginModal]);

  const _buy = () => {
    if (logged) {
      setPayModal(true);
    } else {
      setLoginModal(true);
    }
  };

  return (
    <>
      <div className="section has-text-centered">
        <div className="field is-grouped is-grouped-multiline">
          {products.map((product, index) => {
            return (
              <div className="control" key={index}>
                <div className="tags has-addons">
                  <p className="tag is-link">{product.name}</p>
                  <p
                    className="tag is-delete"
                    onClick={() => deselect(product.id)}
                  ></p>
                </div>
              </div>
            );
          })}
        </div>
        {price > 0 ? (
          <p>المجموع: {price} ريال</p>
        ) : (
          <p>اختر من المنتجات أعلاه بالضغط عليها</p>
        )}
        <div className="field is-grouped is-grouped-centered mt-5">
          <p className="control">
            <Link className="button" to="/client/categories">
              عودة
            </Link>
          </p>
          <p className="control">
            <button
              className="button is-link"
              disabled={price > 0 ? false : true}
              onClick={_buy}
            >
              {logged ? "شراء" : "سجل الدخول للشراء"}
            </button>
          </p>
        </div>
      </div>
      <Login modal={loginModal} setModal={setLoginModal} />
      <Pay
        modal={payModal}
        setModal={setPayModal}
        cart={cart}
        products={products}
        amount={price}
      />
    </>
  );
};

export default Cart;
