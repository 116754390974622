import React from "react";
import axios from "axios";
import { toast } from "bulma-toast";
import server from "../../../../utilities/server";

const CategoryIcon = ({ modal, setModal, refetch, category }) => {
  const [icon, setIcon] = React.useState(null);
  const [iconData, setIconData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const _selectIcon = (icon) => {
    setIcon(icon.target.files[0]);
  };
  const _deselectIcon = () => {
    setIcon(null);
  };

  const ShowIcon = () => {
    if (!iconData) {
      return null;
    }
    return (
      <div id="icon" className="has-text-centered p-3">
        <p className="delete" aria-label="close" onClick={_deselectIcon}></p>
        <img
          src={iconData}
          style={{ maxWidth: 150, maxHeight: 150 }}
          alt="Category Icon"
        />
        <br />
        {category.icon && typeof category.icon === "string" ? (
          <p
            className="button is-danger"
            onClick={_deleteIcon}
            style={loading ? { PointerEvent: "none" } : null}
          >
            حذف الأيقونة
          </p>
        ) : null}
      </div>
    );
  };

  const _deleteIcon = async () => {
    setLoading(true);
    try {
      await axios.delete(`media/category-icon/${category.id}`, {
        params: { name: category.icon },
      });
      toast({
        type: "is-success",
        position: "center",
        message: "تم حذف الأيقونة بنجاح",
      });
      setIconData(null);
      setLoading(false);
    } catch (_) {
      setLoading(false);
      toast({
        type: "is-danger",
        position: "center",
        message: "خطأ أثناء حذف الأيقونة",
      });
    }
  };

  const _submit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("icon", icon);
    formData.append("resize", false);
    server({
      method: "put",
      path: `categories/icon/${category.id}`,
      contentType: "multipart/form-data",
      data: formData,
    })
      .then((updateCategory) => {
        console.log(updateCategory);
        toast({
          type: "is-success",
          position: "center",
          message: "تم رفع الأيقونة بنجاح",
        });
        refetch();
        setModal({ id: 0, name: null });
        setIcon(null);
        setLoading(false);
      })
      .catch((_) => {
        console.log(_.response);
        toast({
          type: "is-danger",
          position: "center",
          message: "خطأ أثناء رفع الأيقونة",
        });
        setModal({ id: 0, name: null });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    async function getIcon() {
      if (category.icon) {
        const iconData = await axios.get("media/category-image", {
          params: { name: category.icon },
          responseType: "blob",
        });
        setIconData(URL.createObjectURL(iconData.data));
      } else {
        setIconData(null);
      }
    }
    getIcon();
    if (icon && typeof icon === "object") {
      let reader = new FileReader();
      reader.onload = (data) => setIconData(data.target.result);
      reader.readAsDataURL(icon);
    }
  }, [icon, modal, category.icon]);

  return (
    <div className={`modal ${modal ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => setModal({ id: 0, name: null })}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">أيقونة القسم {category.name}</p>
          <p
            className="delete"
            aria-label="close"
            onClick={() => setModal({ id: 0, name: null })}
          ></p>
        </header>
        <div className="modal-card-body">
          <div className="file is-boxed is-centered">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="icon"
                onChange={_selectIcon}
              />
              <span className="file-cta">
                <span className="file-label">اختر الأيقونة</span>
              </span>
            </label>
          </div>
          <ShowIcon />
        </div>
        <footer className="modal-card-foot">
          <p
            type="button"
            className="button"
            data-bs-dismiss="modal"
            onClick={() => setModal({ id: 0, name: null })}
          >
            اغلاق
          </p>
          <button
            type="submit"
            className="button is-danger"
            onClick={_submit}
            disabled={loading}
          >
            حفظ
          </button>
        </footer>
      </div>
    </div>
  );
};

export default CategoryIcon;
