import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "bulma-toast";
import server from "../utilities/server";

import MenuLink from "./components/MenuLink";
import menus from "../assets/data/menus.json";
import Password from "./Password";

const Menu = () => {
  const [menu, setMenu] = React.useState(menus.website);
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  React.useEffect(() => {
    menus[path] ? setMenu(menus[path]) : setMenu(menus.website);
  }, [path]);

  const [logged, setLogged] = React.useState(false);
  const isLogged = localStorage.getItem("logged");
  React.useEffect(() => {
    localStorage.getItem("logged") === "true"
      ? setLogged(true)
      : setLogged(false);
  }, [isLogged]);

  const navigate = useNavigate();
  const _logout = () => {
    server({
      method: "delete",
      path: "auth/logout",
    })
      .then(() => {
        toast({
          type: "is-success",
          position: "center",
          message: "تم تسجيل الخروج بنجاح",
        });
        localStorage.removeItem("logged");
        localStorage.removeItem("user");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        navigate("/");
      })
      .catch(() => {
        toast({
          type: "is-danger",
          position: "center",
          message: "خطأ أثناء تسجيل الخروج",
        });
      });
  };

  const [passwordModal, setPasswordModal] = React.useState(false);
  const _password = () => {
    setPasswordModal(true);
  };

  const Logged = () => {
    let role = localStorage.getItem("role");
    if (role && role === "super") role = "admin";
    if (logged) {
      return (
        <>
          <Link to={`/${role}`} className="navbar-item">
            حسابي
          </Link>
          <a onClick={_password} className="navbar-item">
            كلمة المرور
          </a>
          <a onClick={_logout} className="navbar-item">
            خروج
          </a>
          <Password modal={passwordModal} setModal={setPasswordModal} />
        </>
      );
    } else {
      return (
        <>
          <Link to="/user/login" className="navbar-item">
            دخول
          </Link>
          <Link to="/user/signup" className="navbar-item">
            انضم ككاتب
          </Link>
        </>
      );
    }
  };

  const openMenu = () => {
    var burger = document.getElementById("burger");
    var menu = document.getElementById("menu");
    burger.classList.toggle("is-active");
    menu.classList.toggle("is-active");
  };

  return (
    <>
      <nav
        className="navbar is-fixed-top has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-brand">
              <img
                src={require("../assets/images/6abe3.png")}
                alt="طابع"
                width="80"
                height="88"
              />
            </Link>

            <a
              id="burger"
              role="button"
              className="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              onClick={openMenu}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="menu" className="navbar-menu">
            <div className="navbar-start">
              {menu.map((item, index) => (
                <MenuLink
                  key={index}
                  activeLink="/"
                  routerLink={item.link}
                  routerName={item.name}
                />
              ))}
            </div>

            <div className="navbar-end">
              {/* <div className='navbar-item has-dropdown is-hoverable'>
              <a className='navbar-link'>
                |||
              </a>
              <div className='navbar-dropdown'>
                <Link to='/' className='navbar-item'>
                  الرئيسية
                </Link>
                <Link to='/sections' className='navbar-item'>
                  الأقسام
                </Link>
                <Link to='/windows' className='navbar-item'>
                  النوافذ
                </Link>
              </div>
            </div> */}
              <Logged />
            </div>
          </div>
        </div>
      </nav>
      <div
        style={{
          height: "80px",
        }}
      ></div>
    </>
  );
};

export default Menu;
