import React from "react";
import { useQuery } from "react-query";
import { Formik, Form, Field, } from 'formik';
import * as Yup from 'yup';

import { toast } from 'bulma-toast';

import server from "../../../utilities/server";
import ErrorMessage from '../../../common/components/ErrorMessage';
import { dateFormat } from "../../../utilities/helpers";

const History = ({ modal, setModal, reload, product }) => {
  
  const [loading, setLoading] = React.useState(false);
  const [job, setJob] = React.useState({ data: { id: '' } });

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['history'], () => {
    return server({ path: `history/${product.product.id}`, method: 'get' });
  }, { enabled: modal });

  const jobData = useQuery(['job'], () => {
    return server({ path: `jobs/${product.product.id}`, method: 'get', });
  }, { enabled: modal });

  React.useEffect(() => {
    if (jobData.data) {
      setJob(jobData.data)
    }
  }, [jobData]);

  const validate = Yup.object({
    content: Yup.string()
      .min(10, 'لابد أن تحتوي الاضافة على أكثر من عشرة أحرف')
      .required('لابد من ادخال اضافة'),
  });

  return (
    <div className={`modal ${modal ? 'is-active' : ''}`}>
      <Formik
        initialValues={{
          job: job.data.id,
          content: '',
          type: 'progress',
        }}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          server({
            method: 'post',
            path: 'history',
            data: values,
          }).then((_) => {
            toast({ type: 'is-success', position: 'center', message: 'تمت الاضافة بنجاح' });
            refetch();
            setLoading(false);
            resetForm();
          }).catch((_) => {
            toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء الاضافة' });
            refetch();
            setLoading(false);
          });
        }}
      >
        <Form>
          <div className='modal-background' onClick={() => setModal({ id: 0, })}></div>
          <div className='modal-card'>

            <header className='modal-card-head'>
              <p className='modal-card-title'>متابعة { product.product ? product.product.name : null }</p>
              <a className='delete' aria-label='close' onClick={() => setModal({ id: 0, })}></a>
            </header>

            <div className='modal-card-body'>
              <div className='timeline'>
                <div className='timeline-header'>
                  <span className='tag is-medium is-primary'>البداية</span>
                </div>
                {
                  data ? data.data.map((history, index) => {
                    return (
                      <div className='timeline-item' key={index}>
                        <div className='timeline-marker'></div>
                        <div className='timeline-content'>
                          <p className='heading'>{dateFormat(history.created)}</p>
                          <p className='heading'>اضاف: {history.user.name}</p>
                          <p>{history.content}</p>
                        </div>
                      </div>
                    )
                  }) : null
                }
                {
                  job.data.status === 'close' ?
                  <div className='timeline-footer'>
                    <span className='tag is-medium is-primary'>النهاية</span>
                  </div> :
                  null
                }
                {isLoading ? 'تحميل...' : null}
                {isLoadingError || isError ? 'خطأ...' : null}
              </div>
              {
                job.data.status === 'open' ?
                <div className='field mt-5'>
                  <label className='label'>إضافة</label>
                  <div className='control'>
                    <Field as='textarea' name='content' className='textarea' />
                    <ErrorMessage name='content' />
                  </div>
                </div> :
                null
              }
            </div>

            <footer className='modal-card-foot'>
              <a type='button' className='button' data-bs-dismiss='modal' onClick={() => setModal({ id: 0, })}>اغلاق</a>
              {
                job.data.status === 'open' ?
                <button className='button is-link' type='submit' disabled={loading || isLoading}>حفظ</button> :
                null
              }
            </footer>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default History;
