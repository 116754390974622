import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "bulma-toast";
import server from "../../utilities/server";

const ClientCategories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  React.useEffect(() => {
    if (searchParams.get("m") === "success")
      toast({
        type: "is-success",
        position: "center",
        message: "تمت عملية الشراء بنجاح وسيصلك الطلب عبر الايميل.",
      });
    if (searchParams.get("m") === "error")
      toast({
        type: "is-danger",
        position: "center",
        message: "خطأ أثناء عملية الشراء. يرجى مراجعة ادارة الموقع.",
      });
    searchParams.delete("m");
    searchParams.toString();
    setSearchParams(searchParams);
  }, []);

  const ShowIcon = ({ file }) => {
    const [icon, setIcon] = React.useState(null);

    React.useEffect(() => {
      async function getIcon() {
        if (file) {
          const iconData = await axios.get("media/category-image", {
            params: { name: file },
            responseType: "blob",
          });
          setIcon(URL.createObjectURL(iconData.data));
        }
      }
      getIcon();
    }, [file]);
    if (icon) return <img src={icon} style={{ maxWidth: 75, maxHeight: 75 }} />;
    return (
      <img
        src={require("../../assets/images/categories.png")}
        style={{ maxWidth: 75, maxHeight: 75 }}
      />
    );
  };

  const { isLoading, isLoadingError, isError, data } = useQuery(
    ["categories"],
    () => {
      return server({ path: "categories", method: "get" });
    }
  );

  if (isLoading) {
    return <div className="container">تحميل...</div>;
  }

  if (isLoadingError || isError) {
    return <div className="container">خطأ...</div>;
  }

  return (
    <div>
      <div className="home-thaaa"></div>
      <section
        className="container has-text-centered"
        style={{ marginTop: "10rem", maxWidth: "750px" }}
      >
        <h1 className="title has-text-link">طابع الرسائل</h1>
        <div className="columns is-multiline mt-5 pt-5">
          {data.data.map((category, index) => {
            return (
              <React.Fragment key={index}>
                {(index + 1) % 3 == 1 ? <div className="w-100"></div> : null}
                <div className="column is-4 mt-5">
                  <Link to={`/client/products/${category.id}`}>
                    <ShowIcon file={category.icon} />
                    <h4>{category.name}</h4>
                  </Link>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ClientCategories;
