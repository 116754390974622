import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { toast } from "bulma-toast";
import server from "../../utilities/server";
import ErrorMessage from "../../common/components/ErrorMessage";

export default () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const validate = Yup.object({
    name: Yup.string()
      .required("الاسم مطلوب")
      .min(6, "لابد أن يتكون الاسم من ٦ أحرف فأكثر"),
    email: Yup.string()
      .email("الايميل غير مكتوب بشكل صحيح")
      .required("الايميل مطلوب"),
    content: Yup.string()
      .min(10, "لابد أن يكون المحتوى من ١٠ أحرف فأكثر")
      .required("المحتوى مطلوب"),
  });

  const place = `لكي تنجح يجب ان تكون جاد في كل خطوة تقوم بها يجب على كل افعالك الاتسام بالجدية و عارف طريقك كويس جدا كل هذا الكلام ليس تنمية بشرية انما طرق مجربه وفعالة يجب ان تكون على علم بما تفعل و يجب ان يكون كل شيء تفعلة باقصى درجات الاستمتاع فالشغف و الاستمتاع علامة فارقة في الكتابة`;

  return (
    <>
      <div className="home-thaaa"></div>
      <div className="hero is-large">
        <div className="hero-body">
          <div className=" flex">
            <div className=" contact-us">
              <h1 className="title pb-1 has-text-link">مساعدة</h1>
              <p className="subtitle has-text-link mb-5">
                يمكنك التواصل معنا عن طريق النموذج التالي
              </p>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  content: "",
                }}
                validationSchema={validate}
                onSubmit={(values) => {
                  setLoading(true);
                  server({
                    method: "post",
                    path: "contact",
                    data: values,
                  })
                    .then(() => {
                      setLoading(false);
                      toast({
                        type: "is-success",
                        position: "center",
                        message:
                          "تم استلام رسالتك بنجاح. وسيتم النواصل معك في أقرب وقت.",
                      });
                      navigate("/");
                    })
                    .catch(() => {
                      setLoading(false);
                      toast({
                        type: "is-danger",
                        position: "center",
                        message: "خطأ أثناء الارسال",
                      });
                    });
                }}
              >
                <Form>
                  <div className="field">
                    <label className="label has-text-link">الاسم</label>
                    <div className="control">
                      <Field name="name" className="input mb-1" />
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-link">
                      البريد الالكتروني
                    </label>
                    <div className="control">
                      <Field name="email" className="input mb-1" />
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-link">الرسالة</label>
                    <div className="control">
                      <Field
                        as="textarea"
                        name="content"
                        className="textarea mb-1"
                        placeholder={place}
                      />
                      <ErrorMessage name="content" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control has-text-centered">
                      <button
                        className="button is-link is-outlined"
                        type="submit"
                        disabled={loading}
                      >
                        إرسال
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
