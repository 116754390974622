import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../../common/Header";

const UserRouter = () => {
  const logged = localStorage.getItem("logged");
  const role = localStorage.getItem("role");
  return logged && role === "user" ? <Outlet /> : <Navigate to="/user/login" />;
};

export default UserRouter;
