import React from 'react';

export const NotifyContext = React.createContext({ show: false, type: null, message: null });
/**
 * 
 * @returns 
 * 
 * Keep for future useage
 * 
 * 
 * 
 */
export const NotifyAction = () => {
  const [notification, setNotification] = React.useState({ show: false, type: null, message: null });

  return {
    setNotification,
    notification,
  }
}
