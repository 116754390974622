import React from "react";
import { ErrorMessage } from "formik";

const ErrorDisplay = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => <p style={{ fontSize: "12px", color: "red" }}>{msg}</p>}
    </ErrorMessage>
  );
};

export default ErrorDisplay;
