import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { toast } from 'bulma-toast';
import server from '../../utilities/server';
import ErrorMessage from '../../common/components/ErrorMessage';
import { Link } from 'react-router-dom';

export default () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const validate = Yup.object({
    name: Yup.string()
      .required('الاسم مطلوب')
      .min(6, 'لابد أن يتكون الاسم من ٦ أحرف فأكثر'),
    email: Yup.string()
      .email('الايميل غير مكتوب بشكل صحيح')
      .required('الايميل مطلوب'),
    password: Yup.string()
      .min(6, 'كلمة المرور لابد أن تحتوى على ٦ حروف فأكثر')
      .required('كلمة المرور مطلوبة'),
  });
  return (
    <>
      <div className='home-thaaa'></div>
      <div className='hero is-large'>
        <div className='hero-body'>
          <div className="columns is-mobile is-centered">
            <div className="column is-half is-desktop">
              <h1 className='title mb-5 pb-5 has-text-link'>تسجيل الكُتّاب</h1>
              <p className='has-text-link mb-5'>
                شـاركنا الحاضـر، صاحـب المـكان ورفيـق الخطـوة والحرفـة، يسـاعدنا ويسـعدنا وجـودك لأنـك
                الأســاس والمكســب لــكل المحــاولات ومنجــم مطالعــة نمــاذج مــن موهبتــك، جــزء مــن
                خبراتــك وتجاربــك، نــوع الكتابــة المناســب. إننــا نســتثمر فــي موهبتــك، ونراهــن علــى براعتــك
              </p>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  password: '',
                  role: 'user',
                }}
                validationSchema={validate}
                onSubmit={(values) => {
                  setLoading(true);
                  server({
                    method: 'post',
                    path: 'auth/signup',
                    data: values,
                  }).then(() => {
                    setLoading(false);
                    toast({ type: 'is-success', position: 'center', message: 'تم التسجيل بنجاح' });
                    navigate('/');
                  }).catch(() => {
                    setLoading(false);
                    toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء التسجيل' });
                  });
                }}
              >
                <Form>
                  <div className='field'>
                    <label className='label has-text-link'>الاسم</label>
                    <div className='control'>
                      <Field name='name' className='input mb-1' />
                      <ErrorMessage name='name' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>البريد الالكتروني</label>
                    <div className='control'>
                      <Field name='email' className='input mb-1' />
                      <ErrorMessage name='email' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>كلمة المرور</label>
                    <div className='control'>
                      <Field name='password' type='password' className='input mb-1' />
                      <ErrorMessage name='password' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>رقم الجوال</label>
                    <div className='control'>
                      <Field name='mobile' type='phone' className='input mb-1' />
                      <ErrorMessage name='password' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>حسابات منصات التواصل الاجتماعي</label>
                    <div className='control'>
                      <Field name='social' type='text' className='input mb-1' />
                      <ErrorMessage name='password' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>نوع الكتابة المفضل</label>
                    <div className='control'>
                      <Field name='style' type='text' className='input mb-1' />
                      <ErrorMessage name='password' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>ُكتاب تقترح وجودهم في "طابع" بتوصية منك</label>
                    <div className='control'>
                      <Field name='writers' type='text' className='input mb-1' />
                      <ErrorMessage name='password' />
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label has-text-link'>ارفاق منتجات كتابية</label>
                    <div className='control'>
                      <Field name='example' type='textarea' className='input mb-1' />
                      <ErrorMessage name='password' />
                    </div>
                  </div>
                  <div className='field'>
                    <div className='control has-text-centered'>
                      <button className='button is-link is-outlined' type='submit' disabled={loading}>احصل على طابعك</button>
                    </div>
                  </div>
                </Form>
              </Formik>
              <div className='field has-text-centered mt-5 pt-5'>
                <label className='label'><Link to='/user/login'>قم بتسجيل الدخول اذا كان لديك حساب</Link></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
