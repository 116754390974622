import React from "react";
import axios from "axios";
import { toast } from "bulma-toast";
import server from "../../../../utilities/server";

const CategoryImage = ({ modal, setModal, refetch, category }) => {
  const [image, setImage] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const _selectImage = (image) => {
    setImage(image.target.files[0]);
  };
  const _deselectImage = () => {
    setImage(null);
  };
  const ShowImage = () => {
    if (!imageData) {
      return null;
    }
    return (
      <div id="theme" className="has-text-centered p-3">
        <p className="delete" aria-label="close" onClick={_deselectImage}></p>
        <img
          src={imageData}
          style={{ maxWidth: 150, maxHeight: 150 }}
          alt="Category"
        />
        <br />
        {category.image && typeof category.image === "string" ? (
          <p
            className="button is-danger"
            onClick={_deleteImage}
            style={loading ? { PointerEvent: "none" } : null}
          >
            حذف الصورة
          </p>
        ) : null}
      </div>
    );
  };

  const _deleteImage = async () => {
    setLoading(true);
    try {
      await axios.delete(`media/category-image/${category.id}`, {
        params: { name: category.image },
      });
      toast({
        type: "is-success",
        position: "center",
        message: "تم حذف الصورة بنجاح",
      });
      setImageData(null);
      setLoading(false);
    } catch (_) {
      setLoading(false);
      toast({
        type: "is-danger",
        position: "center",
        message: "خطأ أثناء حذف الصورة",
      });
    }
  };

  const _submit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("resize", true);
    server({
      method: "put",
      path: `categories/image/${category.id}`,
      contentType: "multipart/form-data",
      data: formData,
    })
      .then((updateCategory) => {
        console.log(updateCategory);
        toast({
          type: "is-success",
          position: "center",
          message: "تم رفع الصورة بنجاح",
        });
        refetch();
        setModal({ id: 0, name: null });
        setImage(null);
        setLoading(false);
      })
      .catch((_) => {
        console.log(_.response);
        toast({
          type: "is-danger",
          position: "center",
          message: "خطأ أثناء رفع الصورة",
        });
        setModal({ id: 0, name: null });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    async function getImage() {
      if (category.image) {
        const imageData = await axios.get("media/category-image", {
          params: { name: category.image },
          responseType: "blob",
        });
        setImageData(URL.createObjectURL(imageData.data));
      } else {
        setImageData(null);
      }
    }
    getImage();
    if (image && typeof image === "object") {
      let reader = new FileReader();
      reader.onload = (data) => setImageData(data.target.result);
      reader.readAsDataURL(image);
    }
  }, [image, modal, category.image]);

  return (
    <div className={`modal ${modal ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => setModal({ id: 0, name: null })}
      ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">صورة القسم {category.name}</p>
          <p
            className="delete"
            aria-label="close"
            onClick={() => setModal({ id: 0, name: null })}
          ></p>
        </header>
        <div className="modal-card-body">
          <div className="file is-boxed is-centered">
            <label className="file-label">
              <input
                className="file-input"
                type="file"
                name="theme"
                onChange={_selectImage}
              />
              <span className="file-cta">
                <span className="file-label">اختر الصورة</span>
              </span>
            </label>
          </div>
          <ShowImage />
        </div>
        <footer className="modal-card-foot">
          <p
            type="button"
            className="button"
            data-bs-dismiss="modal"
            onClick={() => setModal({ id: 0, name: null })}
          >
            اغلاق
          </p>
          <button
            type="submit"
            className="button is-danger"
            onClick={_submit}
            disabled={loading}
          >
            حفظ
          </button>
        </footer>
      </div>
    </div>
  );
};

export default CategoryImage;
