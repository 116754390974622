import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { toast } from 'bulma-toast';

import server from '../../../../utilities/server';
import ErrorMessage from '../../../../common/components/ErrorMessage';

const NewCategory = ({ modal, setModal, refetch }) => {
	const [loading, setLoading] = React.useState(false);
	// Handles image file
	const [image, setImage] = React.useState(null);

	const validate = Yup.object({
		name: Yup.string()
			.min(4, 'لابد أن يتكون الاسم من أربعة حروف فأكثر')
			.required('لابد من اضافة اسم القسم'),
	});

	return (
		<div id='new-category' className={`modal ${modal ? 'is-active' : ''}`}>
			<div className='modal-background' onClick={setModal}></div>
			<Formik
				initialValues={{
					name: '',
				}}
				validationSchema={validate}
				onSubmit={(values, { resetForm }) => {

					// const formData = new FormData();
					// formData.append('name', values.name);
					// formData.append('content', values.content);
					// if (values.image) formData.append('image', values.image);
					// if (values.image) formData.append('resize', true);

					setLoading(true);
					server({
						method: 'post',
						path: 'categories',
						data: values,
					}).then((_) => {
						toast({ type: 'is-success', position: 'center', message: 'تمت اضافة القسم بنجاح' });
						refetch();
						setModal();
						setLoading(false);
						resetForm();
					}).catch((_) => {
						toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء اضافة القسم' });
						setModal();
						setLoading(false);
					});
				}}
			>
				{(props) => (
					<Form>
						<div className='modal-card'>

							<header className='modal-card-head'>
								<p className='modal-card-title'>قسم جديد</p>
								<a className='delete' aria-label='close' onClick={setModal}></a>
							</header>
							<div className='modal-card-body'>
								<div className='field'>
									<label className='label'>اسم القسم</label>
									<div className='control'>
										<Field name='name' className='input mb-1' autoComplete='off' />
										<ErrorMessage name='name' />
									</div>
								</div>
							</div>
							<footer className='modal-card-foot'>
								<a type='button' className='button' data-bs-dismiss='modal' onClick={setModal}>اغلاق</a>
								<button className='button is-link' type='submit' disabled={loading}>حفظ</button>
							</footer>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
};

export default NewCategory;
