import React from 'react';

import { toast } from 'bulma-toast';
import server from '../../../../utilities/server';

const DeleteCategory = ({ modal, setModal, refetch, data }) => {
	const [loading, setLoading] = React.useState(false);

	const _submit = () => {
		setLoading(true);
		server({
			method: 'delete',
			path: `products/${data.id}`,
		}).then(() => {
			toast({ type: 'is-success', position: 'center', message: 'تمت حذف المنتج بنجاح' });
			refetch();
			setModal({ });
			setLoading(false);
		}).catch(() => {
			toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء حذف المنتج' });
			setModal({ });
			setLoading(false);
		});
	}

	return (
		<div id='new-category' className={`modal ${modal ? 'is-active' : ''}`}>
			<div className='modal-background' onClick={() => setModal({ })}></div>
			<div className='modal-card'>
				<header className='modal-card-head'>
					<p className='modal-card-title'>حذف المنتج {data.name}</p>
					<a className='delete' aria-label='close' onClick={() => setModal({ })}></a>
				</header>
				<div className='modal-card-body'>
					<div className='notification is-danger'>
						سيتم حذف المنتج وجميع الاضافات المرتبطة به. هل أنت متأكد من الاستمرار؟
					</div>
				</div>
				<footer className='modal-card-foot'>
					<a type='button' className='button' data-bs-dismiss='modal' onClick={() => setModal({ })}>اغلاق</a>
					<button type='button' className='button is-danger' onClick={ _submit } disabled={loading}>حذف</button>
				</footer>
			</div>
		</div>
	)
};

export default DeleteCategory;
