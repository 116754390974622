import React from 'react';

import { toast } from 'bulma-toast';
import server from '../../../../utilities/server';

const HideCategory = ({ modal, setModal, refetch, data }) => {
	const [loading, setLoading] = React.useState(false);

	const _submit = () => {
		setLoading(true);
		server({
			method: 'put',
			path: `categories/${data.id}/status`,
			data: {
				status: data.status === 'draft' ? 'publish' : 'draft',
			},
		}).then(() => {
			toast({ type: 'is-success', position: 'center', message: 'تمت تحديث القسم بنجاح' });
			refetch();
			setModal({ id: 0, name: null });
			setLoading(false);
		}).catch(() => {
			toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء تحديث القسم' });
			setModal({ id: 0, name: null });
			setLoading(false);
		});
	}

	return (
		<div className={`modal ${ modal ? 'is-active' : '' }`}>
			<div className='modal-background' onClick={ () => setModal({ id: 0, name: null }) }></div>
			<div className='modal-card'>
				<header className='modal-card-head'>
					<p className='modal-card-title'>{ data.status === 'draft' ? 'نشر' : 'اخفاء' }  { data.name }</p>
					<a className='delete' aria-label='close' onClick={ () => setModal({ id: 0, name: null }) }></a>
				</header>
				<div className='modal-card-body'>
					<div className='notification is-warning'>
						{ data.status === 'draft' ?
							'سيتم نشر القسم وستظهر جميع المنتجات المرتبطة به. هل أنت متأكد من الاستمرار؟'
						: 
							'سيتم اخفاء القسم وجميع المنتجات المرتبطة به. هل أنت متأكد من الاستمرار؟'
						}
					</div>
				</div>
				<footer className='modal-card-foot'>
					<a type='button' className='button' data-bs-dismiss='modal' onClick={ () => setModal({ id: 0, name: null }) }>اغلاق</a>
					<button type='submit' className='button is-warning' onClick={ _submit } disabled={ loading }>{ data.status === 'draft' ? 'نشر' : 'اخفاء' }</button>
				</footer>
			</div>
		</div>
	)
};

export default HideCategory;
