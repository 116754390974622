import React from 'react';

import sectionsData from '../../assets/data/pages/sections.json';

export default () => {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    setData(sectionsData.digital);
  }, []);
  return (
    <div className='mt-5 mb-5'>
      <div className='thaaa-glow-all thaaa-glow-digital'>
        <section className='container'>
          <div className='content-width digital-back'>
            <div className='columns'>
              <div className='column is-8'>
                <h1 className='has-text-white mt-15 title'>{ data.title }</h1>
                <p className='has-text-white is-size-5'>{ data.content }</p>
              </div>
              <div className='column'></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
