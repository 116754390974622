import React from "react";
import axios from "axios";

const ProductFile = ({ modal, setModal, product }) => {
  const [fileData, setFileData] = React.useState(null);

  const _getImage = async (file) => {
    const image = await axios.get("media/product", {
      params: { name: file },
      responseType: "blob",
    });
    setFileData(URL.createObjectURL(image.data));
  };

  React.useEffect(() => {
    if (product.image && typeof product.image === "string") {
      // get file
      // add delete function
      _getImage(product.image);
    } else {
      setFileData(null);
    }
  }, [product]);

  return (
    <div className={`modal ${modal ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => setModal({ id: 0 })}
      ></div>

      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">صورة المنتج {product.name}</p>
          <a
            className="delete"
            aria-label="close"
            onClick={() => setModal({ id: 0 })}
          ></a>
        </header>

        <div className="modal-card-body">
          <div className="has-text-centered p-3">
            <img src={fileData} style={{ maxWidth: 550, maxHeight: 550 }} />
            <br />
          </div>
        </div>

        <footer className="modal-card-foot">
          <p
            type="button"
            className="button"
            data-bs-dismiss="modal"
            onClick={() => setModal({ id: 0 })}
          >
            اغلاق
          </p>
        </footer>
      </div>
    </div>
  );
};

export default ProductFile;
