import React from 'react';
import { useQuery } from 'react-query';

import server from '../../../../utilities/server';

const ProductUser = ({ Field, ErrorMessage }) => {

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['writers'], () => {
    return server({ path: 'users', method: 'get', data: { role: 'user', } });
  });

  if(isLoading) {
    return <div className='container'>تحميل الأعضاء...</div>
  }

  if(isLoadingError || isError) {
    return <div className='container'>خطأ عند تحميل الأعضاء</div>
  }

  return (
    <>
      <div className='field'>
        <label className='label'>اختيار الكاتب</label>
        <div className='control'>
          <Field as='select' name='user' className='select mb-1 is-fullwidth'>
            <option></option>
            {
              data.data.map((user, inedx) => {
                return <option value={ user.id } key={ inedx }>{ user.name }</option>
              })
            }
          </Field>
          <ErrorMessage name='user' />
        </div>
      </div>
      <div className='field'>
        <label className='label'>المطلوب من الكاتب</label>
        <div className='control'>
          <Field as='textarea' name='requirement' className='textarea' />
        </div>
        <ErrorMessage name='requirement' />
      </div>
    </>
  )
}

export default ProductUser;
