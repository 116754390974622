import React from 'react';
import { useQuery } from 'react-query';

import server from '../../../../utilities/server';

const ProductCategories = ({ selectCategory }) => {

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['categories'], () => {
    return server({ path: 'categories', method: 'get' });
  });

  if(isLoading) {
    return <div className='container'>تحميل الأقسام...</div>
  }

  if(isLoadingError || isError) {
    return <div className='container'>خطأ عند تحميل الأقسام</div>
  }

  return (
    <div className='field is-horizontal'>
      <div className='field-label is-normal'>
        <label className='label'>اختر القسم لعرض منتجاته</label>
      </div>
      <div className='field-body'>
        <div className='field is-narrow'>
          <div className='control'>
            <select name='category' className='select is-fullwidth' onChange={ (e) => selectCategory(e.target.value) }>
              <option value={ 0 }>الكل</option>
              {
                data.data.map((category, inedx) => {
                  return <option value={ category.id } key={ inedx }>{ category.name }</option>
                })
              }
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCategories;
