import React from 'react';

const About = () => {
  
  return (
    <section className='container'>
      <div className='home-thaaa'></div>
      <h1 className='mt-6 pt-6 title has-text-link'>تعرف علينا</h1>
      <h2 className='has-text-centered is-size-4 mt-5 pt-5 has-text-link'>قصتنا</h2>
      <div className='container has-text-centered'>
        <div className='pb-5 mb-5'>
          <div className='has-text-centered'>
            <img src={require('../../assets/images/about.png')} width={120} height={120} />
          </div>
        </div>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter' style={{ textAlign: 'justify', }}>
          قصتنا: حلول ربحية وإبداعية وفق معايير أخلاقية. وبداية، فإن أول ما يتبادر إلى الذهن عن كلمة طابع يتمثل في مستطيل أطرافه مسننة وداخله مَعلم لمكان ما وتاريخ وقيمة نقدية، في الواقع هذا التصور طبيعي وواقعي في جانب منه، لكن في الجانب الآخر فإن الطابع مفهوم يشمل البصمة والدمغة، الانعكاس والأثر، المعنى والشكل. الطابع بتكامل أجزائه يشمل المكان والإنسان، المكان الذي يتجسد في أيقونة الطابع ويكون وسيطًا وسفيرًا من جهة إلى وجهة من العالم، الإرث والتباهي وتوقيع الجغرافيا العابر للحدود. في حين أن الإنسان هو الرسالة والحكاية والوصل والتواصل، الخلاق والملهم والمبدع القادر على إضفاء دهشة متجددة.
        </p>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter has-text-centered has-text-danger' style={{ textAlign: 'justify', }}>
          طابع هو من إلى الواصفة للجزء والكل، المعبرة عن الطرفين في كل شيء، أنت وأنت، أنت والآخر، أنت والحياة، أنت دومًا طرف في شيء ما، وجهة من وجهة.
        </p>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter' style={{ textAlign: 'justify', }}>
        طابع أيقونة، ويكبر ويصير كرة أرضية، يمثل العالم، يجسد الشغف. لذلك فإن "طابع" منصة تقدم تجربة مبتكرة في الحصول على منتجات صنعت خصيصًا لتناسب المستخدم، تعبر عنه وتصفه وتترجم شعوره. إجمالًا، "طابع" هو اللسان والصوت والوسيط بين أفكار الشخص وما يرغب في قوله، سواء كان المستخدم فردًا أم جهة. التركيز على انتقاء الفكرة المناسبة، لا يعني الأكثر جمالًا أو بريقًا، بل التي تصف بدقة ووضوح ومباشرةٍ وذكاء ما يحتاجه العميل، وهذا ينعكس ويُستوحى من العنوان؛ طابع. وهو يعني الطابع البريدي والشكل والفرادة في مفهوم الرسالة، التي تكتب بشكل خاص لتعبر عن إحساس خاص وتُوجه إلى شخص بعينه. بصريّا، نحن نتيجة للعالم الحبري الأزرق، وشكل الحروف وعلامات الترقيم والمسافات والنُقط، نتأثر بالرسائل والظرف والطوابع البريدية وحياة ساعي البريد. تقريبًا هذه التفاصيل هي ما ينبت ويصبغ أصابعنا.  
        </p>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter has-text-centered' style={{ textAlign: 'justify', }}>
        إنها منافسة بين الأشياء الجميلة لتكون فاتنة وجاذبة، الرغبة في كل ما يمكنه أن يصير جميلًا لكنه بحاجة الظهور إلى العلن حتى يُكتشف، الحاجة لمكان يتكفل بتوفير بيئة تحفز المبدع وتهبه قيمة ما يبرع فيه.
        </p>
      </div>
    </section>
  )
}

export default About;
