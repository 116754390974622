import React from 'react';

const Join = () => {
  
  return (
    <section className='container'>
      <div className='home-thaaa'></div>
      <h1 className='mt-6 pt-6 title has-text-link'>تعرف علينا</h1>
      <h2 className='has-text-centered is-size-4 mt-5 pt-5 has-text-link'>انضم إلينا</h2>
      <div className='container has-text-centered'>
        <div className='pb-5 mb-5'>
          <div className='has-text-centered'>
            <img src={require('../../assets/images/join.png')} width={120} height={120} />
          </div>
        </div>
      </div>
      <div className='columns mb-6'>
        <p className='column is-half is-offset-one-quarter has-text-centered' style={{ textAlign: 'justify', }}>
        بمجرد وصولك إلى "طابع" فأنت جزء أصيل وركن أساسي في حياة وحيوية المكان، لأننا نعتبر وصولك مكسبًا وإضافة. 
        </p>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter has-text-centered has-text-danger' style={{ textAlign: 'justify', }}>
        بداية: ما هي أساسيات العمل والدليل الإجرائي؟
        </p>
      </div>
      <div className='columns mb-6'>
        <p className='column is-half is-offset-one-quarter' style={{ textAlign: 'justify', }}>
        بالتأكيد فإننا نرغب بمشاركتك في التعرف على "طابع"، بوصفه منصة لتداول الإبداع والدخول إلى الإلهام والاستثمار في الجمال، وحتى نبسط ونشرح آلية العمل، فإن الدليل الإجرائي الخاص بمنصة "طابع" يستند على ثلاثة مرتكزات أساسية: مقدم الخدمة وطالب الخدمة باعتبارهما طرفي المعادلة في "طابع"، والمعالجة بين طرفي المعادلة هي مساحة عمل "طابع"، التي تمثل الحاضنة. مقدم الخدمة: هو كل شخص يمتلك الحرفة في مجال إبداعي. طالب الخدمة: هو كل شخص يرغب في الحصول على منتج جاهز أو مفصل على قياس حاجته، سواء لنفسه أو جهة عمله أو مشروعه. "حاضنة طابع": هي معمل الإنتاج وضبط الجودة والتحقق من تطبيق معايير الإتقان وتوفير البيئة المثالية للتفاعل والمحفز على الإبداع.
        </p>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter has-text-centered has-text-danger' style={{ textAlign: 'justify', }}>
        ماذا يحدث خلال كل دورة عمل؟
        </p>
      </div>
      <div className='columns'>
        <p className='column is-half is-offset-one-quarter' style={{ textAlign: 'justify', }}>
        رحلة المنتجات ودورة العمل في "طابع" تسير وفق مسارين، الأول: المنتجات التي تصنع من "طابع" وتقدم مباشرة للراغبين في امتلاك تحفة فنية مهما اختلف شكل القالب، المسار الثاني: الطلبات المقدمة من العملاء. وفي كلا المسارين يعمل "مجتمع طابع" وفق السياسة المتفق عليها، المعروضة ضمن عقد العمل في كل منتج، ويحصل الشريك بالمقابل على حصته من بيع المنتج، بحسب ما يرد ضمن العقد، في حين يحصل العميل على المنتج المختار أو المطلوب والخاضع في كل مرة للمراجعة والتدقيق وضمانة "طابع".
        </p>
      </div>
    </section>
  )
}

export default Join;
