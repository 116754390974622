import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "../../css/invoices.css";
import { useQuery } from "react-query";
import Server from "../../utilities/server";
const res = `

{
  "id": 4,
  "serial": "20230004",
  "status": "unpaid",
  "amount": "1",
  "createdAt": "2022-09-05T13:11:41.368Z",
  "order": {
      "id": 65,
      "products": [
          {
              "productId": 10,
              "orderId": 65,
              "product": {
                  "name": "يوم الأم",
                  "image": "85e8c98f_61a0c0d666532c48_18-01.png",
                  "price": "1",
                  "category": {
                      "name": "رسائل عاطفية"
                  }
              }
          }
      ]
  },
  "user": {
      "id": 55,
      "name": "ffff",
      "email": "ff"
  }
}
`;
const Invoices = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  const {
    isLoading,
    isLoadingError,
    isError,
    data: response,
  } = useQuery(
    ["invoices"],
    () => {
      return Server({
        path: "invoices/" + id + "/public?token=" + token,
        method: "get",
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <div className="container">تحميل...</div>;
  }

  if (isLoadingError || isError || !token) {
    return <div className="container">خطأ...</div>;
  }
  const { data } = response;
  console.log("🚀 ~ file: Invoices.js:64 ~ Invoices ~ data:", data);

  return (
    <section className="invoices">
      <div className="container">
        <header className="border-b">
          <img
            src={require("../../assets/images/6abe3.png")}
            alt="طابع"
            width="80"
            height="88"
          />
          <p>فاتورة</p>
          <span>#{data.serial}</span>
        </header>
        <div className="content">
          <div className="border-b flex ">
            <div className="w-half">
              <p>
                فاتورة ضريبية:
                <span>#{data.serial}</span>
              </p>
            </div>
            <div className="w-half">
              <p>
                تفاصيل الدفع: <span>{data.status}</span>
              </p>
            </div>
          </div>
          <div className="border-b flex ">
            <div className="w-half">
              <p>
                رقم الطلب:
                <span>#{data.order.id}</span>
              </p>
              <p>
                مسلسل الفاتورة:
                <span>#{data.serial}</span>
              </p>
              <p>
                الرقم الضريبي:
                <span>{"data"}</span>
              </p>
            </div>
            <div className="w-half">
              <p>
                طريق الدفع: <span>{data.paymentType}</span>
              </p>
              <p>
                المبلغ: <span>{data.amount}</span>
              </p>
            </div>
          </div>

          <div className="border-b flex ">
            <div className="w-half">
              <p>مصدرة من:</p>
              <p>
                الاسم:
                <span>{"data"}</span>
              </p>
              <p>
                البريد الالكتروني: <span>{"data"}</span>
              </p>
            </div>
            <div className="w-half">
              <p>مصدرة الى:</p>
              <p>
                الاسم:
                <span>{data.user?.name}</span>
              </p>
              <p>
                البريد الالكتروني:
                <a href={`mailto:${data.user?.email}`}>
                  <span>{data.user?.email}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="table-container container">
          <table>
            <thead className=" border-b ">
              <tr>
                <th className="w-grow  py-1 ">المنتج</th>
                <th className="w-grow  py-1 ">الصنف</th>
                <th className="w-third py-1 ">السعر</th>
                <th className="w-third py-1 ">الكمية</th>
                <th className="w-third py-1 ">المجموع</th>
              </tr>
            </thead>
            <tbody>
              {data.order.products.map((element) => {
                return (
                  <tr key={element.id} className="border-b">
                    <td className="w-grow  py-2 ">{element.name}</td>
                    <td className="w-grow  py-2 ">{element.catagory}</td>
                    <td className="w-third py-2 ">{element.price}</td>
                    <td className="w-third py-2 ">1</td>
                    <td className="w-third py-2 ">{element.price}</td>
                  </tr>
                );
              })}
              <tr className="border-b">
                <td className="w-grow  py-2">الضريبة</td>
                <td className="w-grow py-2"></td>
                <td className="w-third py-2"></td>
                <td className="w-third py-2"></td>
                <td className="w-third py-2">{data.tax}</td>
              </tr>
              <tr className="border-b">
                <td className="w-grow  py-2">المجموع</td>
                <td className="w-grow  py-2"></td>
                <td className="w-third py-2"></td>
                <td className="w-third py-2"></td>
                <td className="w-third py-2">{data.amount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Invoices;
