import React from "react";
import { useQuery } from "react-query";
import Header from "../../common/Header";
import server from "../../utilities/server";
import { dateFormat } from "../../utilities/helpers";

import DeleteOrder from "./components/orders/DeleteOrder";
import DetailsOrder from "./components/orders/DetailsOrder";

const AdminOrders = () => {
  const [orderData, setOrderData] = React.useState({});

  const [detailsModal, setDetailsModal] = React.useState(false);
  const _detailsModal = (data) => {
    setOrderData(data);
    setDetailsModal(!detailsModal);
  };

  const [deleteModal, setDeleteModal] = React.useState(false);
  const _deleteModal = (data) => {
    setOrderData(data);
    setDeleteModal(!deleteModal);
  };

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(
    ["orders"],
    () => {
      return server({ path: "orders", method: "get" });
    }
  );

  if (isLoading) {
    return <div className="container">تحميل...</div>;
  }

  if (isLoadingError || isError) {
    return <div className="container">خطأ...</div>;
  }

  const Actions = ({ data }) => {
    return (
      <td width="30">
        <div className="is-pulled-left">
          <div className="field is-grouped">
            <div className="control">
              <button
                className="button is-info is-light is-small"
                onClick={() => _detailsModal(data)}
              >
                تفاصيل
              </button>
            </div>
            <div className="control">
              <button
                className="button is-light is-danger is-small"
                onClick={() => _deleteModal(data)}
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      </td>
    );
  };

  return (
    <div>
      <Header type="admin" />
      <div className="container">
        <div className="table-container">
          <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th width="10">#</th>
                <th>الطلب</th>
                <th className="has-text-centered">الإجراء</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((order, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>
                      {" "}
                      -
                      <small>
                        {" "}
                        <strong>الطلب:</strong> {order.id}
                      </small>
                      ،
                      <small>
                        {" "}
                        <strong>القيمة:</strong> {order.amount} ريال
                      </small>
                      ،
                      <small>
                        {" "}
                        <strong>التاريخ:</strong> {dateFormat(order.created)}
                      </small>
                      ،
                      <small className="is-size-7">
                        {" "}
                        <strong>الحالة:</strong>{" "}
                        {order.paymentStatus === "paid"
                          ? "تم الدفع"
                          : "غير مدفوع"}
                      </small>
                      .
                    </td>
                    <Actions data={order} />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <DetailsOrder
        modal={detailsModal}
        setModal={_detailsModal}
        order={orderData}
      />
      <DeleteOrder
        modal={deleteModal}
        setModal={_deleteModal}
        refetch={refetch}
        data={orderData}
      />
    </div>
  );
};

export default AdminOrders;
