import React from 'react';
import bg from '../../assets/images/terms-back.png';

const Terms = () => {
  
  return (
    <section className='container'>
      <div className='home-thaaa'></div>
      <h1 className='mt-6 pt-6 title has-text-link has-text-centered'>مبادئنا</h1>
      <h2 className='has-text-centered is-size-4 mt-5 pt-5 has-text-link'>سياسة الخصوصية والاستخدام</h2>
      <div className='container'>
        <div className='pb-5 mb-5'>
          <div className='has-text-centered'>
            <img src={require('../../assets/images/terms.png')} width={280} height={280} />
          </div>
        </div>
      </div>
      <div className='columns mb-6'>
        <p
          className='column is-half is-offset-one-quarter is-size-4'
          style={{ textAlign: 'justify', backgroundImage: `url(${bg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', padding: '8.5rem 6rem' }}
        >
          لأنه لا أحد يتنازل عن اقتحام خصوصيته، هنا نؤكد أننا نتعهد على حماية خصوصية زوار وأعضاء طابع وأن نوضح بشكل مستمر كيف يمكن لنا التعامل مع المعلومات الشخصية. فإن هذه المساحة تخضع لقوانين ثابتة المعالم وواضحة الحدود وشروط ومعايير شفّافة، أي استخدام خارجي لأي منتج، أو اجتزاء وتجاوز لاستخدام شخصي وخاص يُحاسب عليه قانونيًا.
        </p>
      </div>
    </section>
  )
}

export default Terms;
