import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import server from '../../utilities/server';

import ShowImage from './components/ShowImage';
import Cart from './components/Cart';

const ClientProducts = () => {

  const { category } = useParams();
  const [cart, setCart] = React.useState([]);

  const getCategory = useQuery(['category'], () => server({ path: `categories/${category}`, method: 'get' }));
  
  const { isLoading, isLoadingError, isError, data } = useQuery(['products'], () => {
    return server({ path: `products/${category}`, method: 'get' });
  });

  if (isLoading) {
    return <div className='container'>تحميل...</div>
  }

  if (isLoadingError || isError) {
    return <div className='container'>خطأ...</div>
  }

  const _addToCart = (product) => {
    if(!cart.includes(product)) setCart([...cart, product]);
  }

  const _removeFromCart = (product) => {
    if(cart.includes(product)) {
      const index = cart.indexOf(product);
      if(index > -1) cart.splice(index, 1);
      setCart([...cart]);
    }
  }

  return (
    <div className='container pt-5 pb-5 mt-5 mb-5'>
      <div className='pt-5 pb-5 mt-5 mb-5 columns'>
        <div className='column is-three-fifths is-offset-one-fifth'>
          <p className='title has-text-link-dark fs-2 fw-bold mt-4'>{ getCategory.data && getCategory.data.data ? getCategory.data.data.name : null }</p>
          <figure className='image' >
            <div className='tags bottom-left'>
              {
                data.data.map((product, index) => {
                  return <span className='tag' key={ index }><a onClick={ () => _addToCart(product.id) }>{ product.name }</a></span>
                })
              }
            </div>
            <ShowImage file={ getCategory.data && getCategory.data.data ? getCategory.data.data.image : null } />
          </figure>
          <Cart data={ data.data } selected={ cart } deselect={ _removeFromCart } />
        </div>
      </div>
    </div>
  )
}

export default ClientProducts;
