import React from "react";
import { Link } from "react-router-dom";

import homeData from "../../assets/data/pages/main.json";

const Registeration = () => {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    setData(homeData.registration);
  }, []);
  return (
    <div className="mt-5 mb-5">
      <div className="thaaa-glow-all thaaa-glow-register">
        <section className="container">
          <div className="content-width registeration-back">
            <div className="columns">
              <div className="column is-8">
                <h1 className="has-text-white mt-15 title">{data.title}</h1>
                <p className="has-text-white is-size-5">{data.content}</p>
              </div>
              <div className="column"></div>
            </div>
          </div>
        </section>
      </div>
      <div className="container pt-5 pb-5 mt-5 mb-5 has-text-centered">
        <div className="pt-5 pb-5 mt-5 mb-5">
          <Link to="/client/categories">
            <div className="has-text-centered click">
              <img
                src={require("../../assets/images/main/open-envelope.png")}
                width={80}
                height={80}
                alt=""
              />
            </div>
          </Link>
          <p className="title has-text-link-dark fs-2 has-text-weight-bold mt-4">
            طابع الرسائل
          </p>
        </div>
      </div>
    </div>
  );
};

export default Registeration;
