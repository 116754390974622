import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { toast } from "bulma-toast";

import ProductCategory from "./ProductCategory";
import ProductUser from "./ProductUser";
import ProductContent from "./ProductContent";

import server from "../../../../utilities/server";
import ErrorMessage from "../../../../common/components/ErrorMessage";

const NewProduct = ({ modal, setModal, refetch }) => {
  const [loading, setLoading] = React.useState(false);

  // Handles image file
  // const [file, setFile] = React.useState('');

  const validate = Yup.object({
    name: Yup.string()
      .min(2, "لابد أن يتكون الاسم من حرفين فأكثر")
      .required("لابد من اضافة اسم المنتج"),
    category: Yup.string().required("لابد من اختيار القسم"),
    price: Yup.number()
      .typeError("لابد أن يتكون الحقل من أرقام لاتينية فقط")
      .required("لابد من اضافة سعر للمنتج"),
    status: Yup.string().required("لابد من اختيار طريقة اضافة المنتج"),
    content: Yup.string().when("status", {
      is: "publish",
      then: Yup.string().required("لابد من اضافة محتوى المنتج"),
    }),
    user: Yup.string().when("status", {
      is: "assign",
      then: Yup.string().required("لابد من اختيار الكاتب"),
    }),
    requirement: Yup.string().when("status", {
      is: "assign",
      then: Yup.string().required("لابد من اضافة المطلوب من الكاتب"),
    }),
  });

  return (
    <div className={`modal ${modal ? "is-active" : ""}`}>
      <Formik
        initialValues={{
          name: "",
          tempName: "",
          category: "",
          price: "",
          status: "",
          user: "",
          requirement: "",
          content: "",
          history: "new",
          // image: file,
        }}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          if (values.requirement) values.content = values.requirement;
          values.history = "new";

          setLoading(true);
          server({
            method: "post",
            path: "products",
            data: values,
          })
            .then(() => {
              toast({
                type: "is-success",
                position: "center",
                message: "تمت اضافة المنتج بنجاح",
              });
              refetch();
              setModal();
              setLoading(false);
              resetForm();
            })
            .catch(() => {
              toast({
                type: "is-danger",
                position: "center",
                message: "خطأ أثناء اضافة المنتج",
              });
              setModal();
              setLoading(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div className="modal-background" onClick={setModal}></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">منتج جديد</p>
                <a className="delete" aria-label="close" onClick={setModal}></a>
              </header>
              <div className="modal-card-body">
                <div className="field">
                  <label className="label">اسم المنتج</label>
                  <div className="control">
                    <Field
                      name="name"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">الاسم المؤقت</label>
                  <div className="control">
                    <Field
                      name="tempName"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="tempName" />
                  </div>
                </div>

                <ProductCategory Field={Field} ErrorMessage={ErrorMessage} />

                <div className="field">
                  <label className="label">سعر المنتج</label>
                  <div className="control">
                    <Field
                      name="price"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="price" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">اضافة المنتج</label>
                  <div className="control">
                    <label className="radio">
                      <Field type="radio" name="status" value="publish" />
                      اضافة مباشرة
                    </label>
                    <label className="radio">
                      <Field type="radio" name="status" value="assign" />
                      تعيين كاتب
                    </label>
                  </div>
                  <ErrorMessage name="status" />
                </div>

                {props.values.status === "assign" ? (
                  <ProductUser Field={Field} ErrorMessage={ErrorMessage} />
                ) : props.values.status === "publish" ? (
                  <ProductContent Field={Field} ErrorMessage={ErrorMessage} />
                ) : null}

                {/* <ProductFile setFieldValue={props.setFieldValue} file={file} setFile={setFile} Field={Field} ErrorMessage={ErrorMessage} /> */}
              </div>
              <footer className="modal-card-foot">
                <a
                  type="button"
                  className="button"
                  data-bs-dismiss="modal"
                  onClick={setModal}
                >
                  اغلاق
                </a>
                <button
                  className="button is-link"
                  type="submit"
                  disabled={loading}
                >
                  حفظ
                </button>
              </footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewProduct;
