import React from "react";
import { useQuery } from "react-query";

import { toast } from "bulma-toast";
import server from "../../../../utilities/server";
import { dateFormat } from "../../../../utilities/helpers";

const DetailsOrder = ({ modal, setModal, order }) => {
  const [loading, setLoading] = React.useState(false);

  const _resendModal = () => {
    setLoading(true);
    server({
      method: "get",
      path: `orders/resend/${order.id}`,
    })
      .then(() => {
        toast({
          type: "is-success",
          position: "center",
          message: "تم إعادة إرسال الطلب بنجاح",
        });
        setModal({});
        setLoading(false);
      })
      .catch(() => {
        toast({
          type: "is-danger",
          position: "center",
          message: "خطأ أثناء إرسال الطلب",
        });
        setModal({});
        setLoading(false);
      });
  };

  return (
    <div id="new-category" className={`modal ${modal ? "is-active" : ""}`}>
      <div className="modal-background" onClick={() => setModal({})}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">تفاصيل الطلب رقم {order.id}</p>
          <a
            className="delete"
            aria-label="close"
            onClick={() => setModal({})}
          ></a>
        </header>
        <div className="modal-card-body">
          <div className="columns">
            <div className="column">
              رقم الطلب: {order.id ? order.id : null}
            </div>
            <div className="column">
              تاريخ الطلب: {order.created ? dateFormat(order.created) : null}
            </div>
          </div>
          <div className="columns">
            <div className="column">
              القيمة: {order.amount ? order.amount : null}
            </div>
            <div className="column">
              حالة الطلب: {order.status === "done" ? "تم الدفع" : "غير مدفوع"}
            </div>
          </div>
          <div className="columns">
            <div className="column">
              اسم العميل: {order.user ? order.user.name : null}
            </div>
            <div className="column">
              المنتجات:{" "}
              {order.products
                ? order.products
                    .map((product) => product.product.name)
                    .join(", ")
                : null}
            </div>
          </div>
          <div className="columns">
            <div className="column">الرقم المرجعي: {order.paymentId}</div>
          </div>

          <div className="columns">
            <div className="column">
              رسالة ميسر: {order.paymentMessage ? order.paymentMessage : null}
            </div>
          </div>
        </div>
        <footer className="modal-card-foot">
          <a
            type="button"
            className="button"
            data-bs-dismiss="modal"
            onClick={() => setModal({})}
          >
            اغلاق
          </a>
          {order.status === "done" ? (
            <button
              type="button"
              className="button is-sucess is-light"
              onClick={() => _resendModal(order)}
              disabled={loading}
            >
              اعادة إرسال المنتج للعميل
            </button>
          ) : null}
        </footer>
      </div>
    </div>
  );
};

export default DetailsOrder;
