import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Header from "../../common/Header";
import server from "../../utilities/server";
import NewProduct from "./components/products/NewProduct";
import UpdateProduct from "./components/products/UpdateProduct";
import HideProduct from "./components/products/HideProduct";
import DeleteProduct from "./components/products/DeleteProduct";
import History from "./components/products/History";
import ProductFile from "./components/products/ProductFile";

import ProductCategories from "./components/products/ProductCategories";

const AdminProducts = () => {
  const [productData, setProductData] = React.useState({});

  const [addModal, setAddModal] = React.useState(false);
  const _addModal = () => {
    setAddModal(!addModal);
  };

  const [updateModal, setUpdateModal] = React.useState(false);
  const _updateModal = (data) => {
    setProductData(data);
    setUpdateModal(!updateModal);
  };

  const [hideModal, setHideModal] = React.useState(false);
  const _hideModal = (data) => {
    setProductData(data);
    setHideModal(!hideModal);
  };

  const [deleteModal, setDeleteModal] = React.useState(false);
  const _deleteModal = (data) => {
    setProductData(data);
    setDeleteModal(!deleteModal);
  };

  const [historyModal, setHistoryModal] = React.useState(false);
  const _historyModal = (data) => {
    setProductData(data);
    setHistoryModal(!historyModal);
  };

  const [imageModal, setImageModal] = React.useState(false);
  const _imageModal = (data) => {
    setProductData(data);
    setImageModal(!imageModal);
  };

  // Category state for refetch
  const [category, setCategory] = React.useState(0);

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(
    ["products"],
    () => {
      return server({ path: `products/manage/${category}`, method: "get" });
    }
  );

  useEffect(() => {
    refetch();
  }, [category]);

  if (isLoading) {
    return <div className="container">تحميل...</div>;
  }

  if (isLoadingError || isError) {
    return <div className="container">خطأ...</div>;
  }

  const AddNew = () => {
    return (
      <div className="is-pulled-left">
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-ghost" onClick={_addModal}>
              اضافة
            </button>
          </div>
          <div className="control">
            <button className="button is-ghost" onClick={refetch}>
              تحديث
            </button>
          </div>
        </div>
      </div>
    );
  };

  const Actions = ({ data }) => {
    return (
      <td width="30">
        <div className="is-pulled-left">
          <div className="field is-grouped">
            {
              //data.job.find((value) => value.status === 'open') ?
              data.job.length > 0 ? (
                <div className="control">
                  <button
                    className="button is-text is-small"
                    onClick={() => _historyModal(data)}
                  >
                    متابعة
                  </button>
                </div>
              ) : data.image ? (
                <div className="control">
                  <button
                    className="button is-text is-small"
                    onClick={() => _imageModal(data)}
                  >
                    الصورة
                  </button>
                </div>
              ) : null
            }
            <div className="control">
              <button
                className="button is-ghost is-small"
                onClick={() => _updateModal(data)}
              >
                تحديث
              </button>
            </div>
            <div className="control">
              <button
                className="button is-info is-light is-small"
                onClick={() => _hideModal(data)}
              >
                {data.status === "draft" ? "نشر" : "اخفاء"}
              </button>
            </div>
            <div className="control">
              <button
                className="button is-light is-danger is-small"
                onClick={() => _deleteModal(data)}
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      </td>
    );
  };

  const Products = ({ product, index }) => {
    if (product.status !== "assign") {
      return (
        <tr>
          <th>{index + 1}</th>
          <td>
            {product.name} {product.tempName ? `(${product.tempName})` : null} -
            <small className="is-size-7">
              {" "}
              الحالة:{" "}
              {product.status === "publish"
                ? "منشور"
                : product.status === "assign"
                ? "تحت الانشاء"
                : "مخفي"}
            </small>
            ,
            <small className="is-size-7"> القسم: {product.category.name}</small>
            ,<small className="is-size-7"> بيع: {product.sold}</small>
          </td>
          <Actions data={product} />
        </tr>
      );
    }
  };

  return (
    <div>
      <History
        modal={historyModal}
        setModal={_historyModal}
        reload={refetch}
        product={productData}
      />
      <ProductFile
        modal={imageModal}
        setModal={_imageModal}
        reload={refetch}
        product={productData}
      />
      <Header type="admin">
        <AddNew />
      </Header>
      <div className="container">
        <NewProduct modal={addModal} setModal={_addModal} refetch={refetch} />
        <UpdateProduct
          modal={updateModal}
          setModal={_updateModal}
          refetch={refetch}
          data={productData}
        />
        <HideProduct
          modal={hideModal}
          setModal={_hideModal}
          refetch={refetch}
          data={productData}
        />
        <DeleteProduct
          modal={deleteModal}
          setModal={_deleteModal}
          refetch={refetch}
          data={productData}
        />
        <div>
          <ProductCategories selectCategory={setCategory} />
        </div>
        <div className="table-container">
          <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th width="10">#</th>
                <th>المنتج</th>
                <th className="has-text-centered">الإجراء</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((product, index) => {
                return <Products product={product} index={index} key={index} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
