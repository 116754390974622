import React from 'react';
import bg from '../../assets/images/faq-back.png';

const FAQ = () => {
  
  return (
    <section className='container'>
      <div className='home-thaaa'></div>
      <h1 className='mt-6 pt-6 title has-text-link has-text-centered'>مساعدة</h1>
      <h2 className='has-text-centered is-size-4 mt-5 pt-5 has-text-link'>الأسئلة الشائعة</h2>
      <div className='container has-text-centered'>
        <div className='pb-5 mb-5'>
          <div className='has-text-centered'>
            <img src={require('../../assets/images/faq.png')} width={250} height={250} />
          </div>
        </div>
      </div>
      <div className='columns mb-6'>
        <div
          className='column is-half is-offset-one-quarter'
          style={{ textAlign: 'justify', backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', padding: '6rem 4rem' }}
        >
          <p className='is-size-4 has-text-centered mb-5'>
          كيف يحمي طابع خصوصيتي ويحافظ على أمان معلوماتي 
          </p>
          <p className='is-size-6 mb-6'>
          نعلم أن الأمان والخصوصية هما عاملان مهمان بالنسبة إليك مثلما هو الأمر بالنسبة إلينا أيضًا. ونضع ضمن أولوياتنا توفير أمان قوي وجعلك واثقًا من أمان معلوماتك وإمكانية وصولك إليها عند الحاجة. نحن نعمل باستمرار لضمان توفير أمان قوي وحماية خصوصيتك وتجدر الإشارة إلى أنه يتم إنفاق مئات الملايين من الدولارات سنويًا على الأمان، فضلاً عن توظيف خبراء ذوي شهرة عالمية في مجال أمان البيانات بهدف الحفاظ على معلوماتك آمنة
          </p>
          <p className='is-size-4 has-text-centered mb-5'>
          كيف يحمي طابع خصوصيتي ويحافظ على أمان معلوماتي 
          </p>
          <p className='is-size-6 mb-6'>
          نعلم أن الأمان والخصوصية هما عاملان مهمان بالنسبة إليك مثلما هو الأمر بالنسبة إلينا أيضًا. ونضع ضمن أولوياتنا توفير أمان قوي وجعلك واثقًا من أمان معلوماتك وإمكانية وصولك إليها عند الحاجة. نحن نعمل باستمرار لضمان توفير أمان قوي وحماية خصوصيتك وتجدر الإشارة إلى أنه يتم إنفاق مئات الملايين من الدولارات سنويًا على الأمان، فضلاً عن توظيف خبراء ذوي شهرة عالمية في مجال أمان البيانات بهدف الحفاظ على معلوماتك آمنة
          </p>
          <p className='is-size-4 has-text-centered mb-5'>
          كيف يحمي طابع خصوصيتي ويحافظ على أمان معلوماتي 
          </p>
          <p className='is-size-6'>
          نعلم أن الأمان والخصوصية هما عاملان مهمان بالنسبة إليك مثلما هو الأمر بالنسبة إلينا أيضًا. ونضع ضمن أولوياتنا توفير أمان قوي وجعلك واثقًا من أمان معلوماتك وإمكانية وصولك إليها عند الحاجة. نحن نعمل باستمرار لضمان توفير أمان قوي وحماية خصوصيتك وتجدر الإشارة إلى أنه يتم إنفاق مئات الملايين من الدولارات سنويًا على الأمان، فضلاً عن توظيف خبراء ذوي شهرة عالمية في مجال أمان البيانات بهدف الحفاظ على معلوماتك آمنة
          </p>
        </div>
      </div>
    </section>
  )
}

export default FAQ;
