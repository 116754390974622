import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { toast } from "bulma-toast";

import ProductCategory from "./ProductCategory";
import ProductContent from "./ProductContent";

import server from "../../../../utilities/server";
import ErrorMessage from "../../../../common/components/ErrorMessage";

const UpdateProduct = ({ modal, setModal, refetch, data }) => {
  const [loading, setLoading] = React.useState(false);
  // Handles image file
  // const [file, setFile] = React.useState('');

  /**
   *
   *
   * if status is assign, only name, category, price, image can be changed
   * then can be updated through history
   *
   * if status is publish or draft, can change name, category, price, content, and image
   *
   *
   */
  const [productData, setProductData] = React.useState({
    name: data.name,
    tempName: data.tempName,
    category: data.category,
    price: data.price,
    content: data.content,
  });
  React.useEffect(() => {
    if (data.id) {
      setProductData({
        name: data.name,
        tempName: data.tempName,
        category: data.category.id,
        price: data.price,
        content: data.content,
      });
      // setFile(data.image);
    }
  }, [data]);

  const validate = Yup.object({
    name: Yup.string()
      .min(2, "لابد أن يتكون الاسم من حرفين فأكثر")
      .required("لابد من اضافة اسم المنتج"),
    category: Yup.string().required("لابد من اختيار القسم"),
    price: Yup.number()
      .typeError("لابد أن يتكون الحقل من أرقام لاتينية فقط")
      .required("لابد من اضافة سعر للمنتج"),
    content: Yup.string()
      .nullable()
      .test("content", "لابد من اضافة محتوى المنتج", (value) => {
        if (!!value) {
          const schema = Yup.string().min(4);
          return schema.isValidSync(value);
        }
        return true;
      }),
  });

  return (
    <div className={`modal ${modal ? "is-active" : ""}`}>
      <Formik
        initialValues={productData}
        enableReinitialize
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          server({
            method: "put",
            path: `products/${data.id}`,
            data: values,
          })
            .then((_) => {
              toast({
                type: "is-success",
                position: "center",
                message: "تمت تحديث المنتج بنجاح",
              });
              refetch();
              setModal({});
              setLoading(false);
              resetForm();
            })
            .catch((_) => {
              toast({
                type: "is-danger",
                position: "center",
                message: "خطأ أثناء تحديث المنتج",
              });
              setModal({});
              setLoading(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div
              className="modal-background"
              onClick={() => setModal({})}
            ></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">تحديث {data.name}</p>
                <a
                  className="delete"
                  aria-label="close"
                  onClick={() => setModal({})}
                ></a>
              </header>
              <div className="modal-card-body">
                <div className="field">
                  <label className="label">اسم المنتج</label>
                  <div className="control">
                    <Field
                      as="input"
                      name="name"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="field">
                  <label className="label">الاسم المؤقت</label>
                  <div className="control">
                    <Field
                      name="tempName"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="tempName" />
                  </div>
                </div>
                <ProductCategory Field={Field} ErrorMessage={ErrorMessage} />

                <div className="field">
                  <label className="label">سعر المنتج</label>
                  <div className="control">
                    <Field
                      name="price"
                      className="input mb-1"
                      autoComplete="off"
                    />
                    <ErrorMessage name="price" />
                  </div>
                </div>

                {data.status && data.status !== "assign" ? (
                  <ProductContent Field={Field} ErrorMessage={ErrorMessage} />
                ) : null}

                {/* <ProductFile setFieldValue={ props.setFieldValue } file={ file } setFile={ setFile } id={ data.id } ErrorMessage={ ErrorMessage } /> */}
              </div>
              <footer className="modal-card-foot">
                <a
                  type="button"
                  className="button"
                  data-bs-dismiss="modal"
                  onClick={() => setModal({})}
                >
                  اغلاق
                </a>
                <button
                  className="button is-link"
                  type="submit"
                  disabled={loading}
                >
                  حفظ
                </button>
              </footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateProduct;
