import React from 'react';
import bg from '../../assets/images/terms-back.png';

const Terms = () => {
  
  return (
    <section className='container'>
      <div className='home-thaaa'></div>
      <h1 className='mt-6 pt-6 title has-text-link has-text-centered'>مبادئنا</h1>
      <h2 className='has-text-centered is-size-4 mt-5 pt-5 has-text-link'>سياسة استرداد المبلغ المدفوع</h2>
      <div className='container'>
        <div className='pb-5 mb-5'>
          <div className='has-text-centered'>
            <img src={require('../../assets/images/terms.png')} width={280} height={280} />
          </div>
        </div>
      </div>
      <div className='columns mb-6'>
        <p
          className='column is-half is-offset-one-quarter is-size-4'
          // style={{ textAlign: 'justify', backgroundImage: `url(${bg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', padding: '8.5rem 6rem' }}
        >
          <strong>تنبيه؛</strong>
          <br />
          لا يمكننا رد المبلغ المدفوع مقابل البطاقات الرقمية التي يتم شراؤها عن طريق الموقع بعد استلامها على بريدكم الالكتروني.
          <br />
          <br />
          إذا كانت الطلبية مسبوقة الدفع عن طريق بطاقة الإئتمان / الخصم، فإننا نوفر لزبائننا الكرام  خدمة استرجاع قيمة المنتجات المدفوعة كاملة على البطاقة الإئتمانية او الPayPal.
          <br />
          <br />
          إذا دفعت طلبك عن طريق بطاقة الائتمان/ الخصم، يمكنك طلب استرداد المبلغ المدفوع إلى بطاقة الائتمان/ الخصم.
          <br />
          <br />
        </p>
      </div>
    </section>
  )
}

export default Terms;
