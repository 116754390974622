import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { toast } from 'bulma-toast';
import server from '../../../../utilities/server';
import ErrorMessage from '../../../../common/components/ErrorMessage';

const NewCategory = ({ modal, setModal, refetch }) => {
	const [loading, setLoading] = React.useState(false);

	const validate = Yup.object({
		name: Yup.string()
			.min(4, 'لابد أن يتكون الاسم من أربعة حروف فأكثر')
			.required('لابد من اضافة اسم العضو'),
		email: Yup.string()
			.email('لابد من كتابة الايميل بشكل صحيح')
			.required('الايميل مطلوب'),
		password: Yup.string()
			.min(6, 'لابد أن تتكون كلمة المرور من ستة أحرف فأكثر')
			.required('لابد من اضافة كلمة المرور'),
		role: Yup.string()
			.required('لابد من اختيار الدور'),
	});

	return (
		<div className={`modal ${modal ? 'is-active' : ''}`}>
			<div className='modal-background' onClick={setModal}></div>
			<Formik
				initialValues={{
					name: '',
					email: '',
					password: '',
					role: '',
				}}
				validationSchema={validate}
				onSubmit={(values, { resetForm }) => {
					setLoading(true);
					server({
						method: 'post',
						path: 'users',
						data: values,
					}).then((_) => {
						toast({ type: 'is-success', position: 'center', message: 'تمت اضافة العضو بنجاح' });
						refetch();
						setModal();
						setLoading(false);
						resetForm();
					}).catch((_) => {
						console.log(_)
						toast({ type: 'is-danger', position: 'center', message: 'خطأ أثناء اضافة العضو. تأكد أن العضو لم تتم اضافته مسبقا' });
						setModal();
						setLoading(false);
					});
				}}
			>
				<Form>
					<div className='modal-card'>

						<header className='modal-card-head'>
							<p className='modal-card-title'>عضو جديد</p>
							<a className='delete' aria-label='close' onClick={setModal}></a>
						</header>
						<div className='modal-card-body'>
							<div className='field'>
								<label className='label'>اسم العضو</label>
								<div className='control'>
									<Field name='name' className='input mb-1' autoComplete='off' />
									<ErrorMessage name='name' />
								</div>
							</div>
							<div className='field'>
								<label className='label'>الايميل</label>
								<div className='control'>
									<Field type='email' name='email' className='input mb-1' autoComplete='off' />
									<ErrorMessage name='email' />
								</div>
							</div>
							<div className='field'>
								<label className='label'>كلمة المرور</label>
								<div className='control'>
									<Field type='password' name='password' className='input mb-1' autoComplete='off' />
									<ErrorMessage name='password' />
								</div>
							</div>
							<div className='field'>
								<label className='label'>الدور</label>
								<div className='control'>
									<Field as='select' name='role' className='select mb-1 is-fullwidth'>
										<option></option>
										<option value='admin'>اداري</option>
										<option value='user'>كاتب</option>
										<option value='client'>عميل</option>
									</Field>
									<ErrorMessage name='role' />
								</div>
							</div>
						</div>
						<footer className='modal-card-foot'>
							<a type='button' className='button' data-bs-dismiss='modal' onClick={setModal}>اغلاق</a>
							<button className='button is-link' type='submit' disabled={loading}>حفظ</button>
						</footer>
					</div>
				</Form>
			</Formik>
		</div>
	)
};

export default NewCategory;
