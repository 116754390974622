import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AdminRouter = () => {
  const logged = localStorage.getItem('logged');
  const role = localStorage.getItem('role');
  return logged && (role === 'super' || role === 'admin') ? <Outlet /> : <Navigate to='/admin/login' />;
}

export default AdminRouter;
