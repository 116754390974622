import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { toast } from "bulma-toast";
import server from "../../utilities/server";
import ErrorMessage from "../../common/components/ErrorMessage";

const Signup = () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const validate = Yup.object({
    name: Yup.string()
      .required("الاسم مطلوب")
      .min(6, "لابد أن يتكون الاسم من ٦ أحرف فأكثر"),
    email: Yup.string()
      .email("الايميل غير مكتوب بشكل صحيح")
      .required("الايميل مطلوب"),
    password: Yup.string()
      .min(6, "كلمة المرور لابد أن تحتوى على ٦ حروف فأكثر")
      .required("كلمة المرور مطلوبة"),
  });
  return (
    <>
      <div className="home-thaaa"></div>
      <div className="hero is-large">
        <div className="hero-body">
          <div className="columns is-mobile is-centered">
            <div className="column is-one-third">
              <h1 className="title mb-5 pb-5 has-text-link">
                عنوانك لتمتلك طابعك
              </h1>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  password: "",
                  role: "client",
                }}
                validationSchema={validate}
                onSubmit={(values) => {
                  setLoading(true);
                  server({
                    method: "post",
                    path: "auth/signup",
                    data: values,
                  })
                    .then(() => {
                      setLoading(false);
                      toast({
                        type: "is-success",
                        position: "center",
                        message:
                          "تم التسجيل بنجاح. لكن لابد من مراجعة بريدك لتفعيل الحساب",
                        duration: 5000,
                      });

                      navigate("/");
                    })
                    .catch((err) => {
                      console.log("error in sign up", err);
                      setLoading(false);
                      toast({
                        type: "is-danger",
                        position: "center",
                        message: "خطأ أثناء التسجيل",
                      });
                    });
                }}
              >
                <Form>
                  <div className="field">
                    <label className="label has-text-link">الاسم</label>
                    <div className="control">
                      <Field name="name" className="input mb-1" />
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-link">
                      البريد الالكتروني
                    </label>
                    <div className="control">
                      <Field name="email" className="input mb-1" />
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label has-text-link">كلمة المرور</label>
                    <div className="control">
                      <Field
                        name="password"
                        type="password"
                        className="input mb-1"
                      />
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control has-text-centered">
                      <button
                        className="button is-link is-outlined"
                        type="submit"
                        disabled={loading}
                      >
                        احصل على طابعك
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
