import React from 'react';
import { useQuery } from 'react-query';
import Header from '../../common/Header';
import server from '../../utilities/server';
import NewUser from './components/users/NewUser';
import UpdateUser from './components/users/UpdateUser';
import ActivateUser from './components/users/ActivateUser';
import DeleteUser from './components/users/DeleteUser';

const AdminUsers = () => {
  
  const [userData, setUserData] = React.useState({ id: 0, name: '', email: '', role: '', });

  const [addModal, setAddModal] = React.useState(false);
  const _addModal = () => {
    setAddModal(!addModal);
  }

  const [updateModal, setUpdateModal] = React.useState(false);
  const _updateModal = (data) => {
    setUserData(data);
    setUpdateModal(!updateModal);
  }
  
  const [hideModal, setHideModal] = React.useState(false);
  const _hideModal = (data) => {
    setUserData(data);
    setHideModal(!hideModal);
  }
  const [deleteModal, setDeleteModal] = React.useState(false);
  const _deleteModal = (data) => {
    setUserData(data);
    setDeleteModal(!deleteModal);
  }

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['users'], () => {
    return server({ path: 'users', method: 'get' });
  });

  if(isLoading) {
    return <div className='container'>تحميل...</div>
  }

  if(isLoadingError || isError) {
    return <div className='container'>خطأ...</div>
  }

  const AddNew = () => {
    return (
      <div className='is-pulled-left'>
        <div className='field is-grouped'>
          <div className='control'>
            <button className='button is-ghost' onClick={ _addModal }>اضافة</button>
          </div>
          <div className='control'>
            <button className='button is-ghost' onClick={ refetch }>تحديث</button>
          </div>
        </div>
      </div>
    )
  }

  const Actions = ({ data }) => {
    return (
      <td width='30'>
        <div className='is-pulled-left'>
          <div className='field is-grouped'>
            <div className='control'>
              <button className='button is-ghost is-small' onClick={ () => _updateModal(data) }>تحديث</button>
            </div>
            <div className='control'>
              { 
                data.role !== 'super' 
                  ? <button className='button is-info is-light is-small' onClick={ () => _hideModal(data) }>{ data.status === 'pending' ? 'تنشيط' : 'تعطيل' }</button> 
                  : null
              }
            </div>
            <div className='control'>
              { 
                data.role !== 'super'
                  ? <button className='button is-light is-danger is-small' onClick={ () => _deleteModal(data) }>حذف</button>
                  : null
              }
            </div>
          </div>
        </div>
      </td>
    )
  }

  return (
    <div>
      <Header type='admin'>
        <AddNew />
      </Header>
      <div className='container'>
        <div className='table-container'>
          <table className='table is-striped is-hoverable is-fullwidth'>
            <thead>
              <tr>
                <th width='10'>#</th>
                <th>العضو</th>
                <th className='has-text-centered'>الإجراء</th>
              </tr>
            </thead>
            <tbody>
              {
                data.data.map((user, index) => {
                  return (
                    <tr key={index}>
                      <th>{ index + 1 }</th>
                      <td>{ user.name } - 
                        <small className='is-size-7'> <strong>الحالة: </strong>
                          { 
                            user.status === 'pending' ? 'معطّل' : 'نشط' 
                          }
                        </small>,
                        <small className='is-size-7'> <strong>الصفة: </strong>
                          { 
                            user.role.toLowerCase() === 'super' ? 'مدير نظام' : user.role.toLowerCase() === 'admin' ? 'اداري' : user.role.toLowerCase() === 'user' ? 'كاتب' : 'عميل' 
                          }
                        </small>,
                        <small className='is-size-7'> <strong>الايميل: </strong> { user.email }</small>
                      </td>
                      <Actions data={ user } />
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      <NewUser modal={ addModal } setModal={ _addModal } refetch={ refetch } />
      <UpdateUser modal={ updateModal } setModal={ _updateModal } refetch={ refetch } data={ userData } />
      <ActivateUser modal={ hideModal } setModal={ _hideModal } refetch={ refetch } data={ userData } />
      <DeleteUser modal={ deleteModal } setModal={ _deleteModal } refetch={ refetch } data={ userData } />
    </div>
  )
}

export default AdminUsers;
