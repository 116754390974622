import React from 'react';
import bg from '../../assets/images/principles-back.png';

const Principles = () => {
  
  return (
    <section className='container'>
      <div className='home-thaaa'></div>
      <h1 className='mt-6 pt-6 title has-text-link has-text-centered'>مبادئنا</h1>
      <h2 className='has-text-centered is-size-4 mt-5 pt-5 has-text-link'>الميثاق الأخلاقي</h2>
      <div className='container has-text-centered'>
        <div className='pb-5 mb-5'>
          <div className='has-text-centered'>
            <img src={require('../../assets/images/principles.png')} width={250} height={250} />
          </div>
        </div>
      </div>
      <div className='columns mb-6'>
        <p
          className='column is-half is-offset-one-quarter is-size-4'
          style={{ textAlign: 'justify', backgroundImage: `url(${bg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', padding: '6rem 3rem' }}
        >
        قبل أي تفسير آخر وقبل نية التفكير في الاستثمار والنتيجة، أملنا في تجربة "طابع" هو تعزيز الرابطة الإنسانية وتقريب المسافات وتجنّب سوء الفهم وبصيرة تنوّرنا وتضيء معالم دربنا، اختبار للتحقق، وتجربة لحجب الانتظار وتجاوز العوائق والتحديات. هذا المشروع رمزه يد مساندة وهويّته هي مشاركة ومراكمة شعورية، انطلقنا منه إلى غاية سامية وارتكزنا فيه على قيمة أخلاقية وأرض محايدة ومشتركة. فسّرنا هذه الرسائل على أنها طمأنة قبل أن تكون استخدامًا ووسيلة، قرأناها على أنها امتنان للقدرة على التعبير والكلام قبل أن تصبح تجارة وسلعة، كتبناها لأننا تسعدنا التجربة وتعنينا المشاركة، ووصلنا معًا إلى هذا الميثاق وحفظنا عهدنا.  
        </p>
      </div>
    </section>
  )
}

export default Principles;
