import React from 'react';
import { Link } from 'react-router-dom';

import homeData from '../../assets/data/pages/main.json';

const Partners = () => {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    setData(homeData.products);
  }, []);
  return (
    <div className='mt-5 mb-5'>
      <div className='thaaa-glow-all thaaa-glow-partners'>
        <section className='container'>
          <div className='content-width partners-back'>
            <div className='columns'>
              <div className='column is-8'>
                <h1 className='has-text-white mt-15 title'>{ data.title }</h1>
                <p className='has-text-white is-size-5'>{ data.content }</p>
              </div>
              <div className='col'></div>
            </div>
          </div>
        </section>
      </div>
      <div className='container pt-5 mt-5'>
        <div className='columns has-text-centered pt-5 mt-5'>
          <div className='column'>
            <div className='text-center'>
              <img src={require('../../assets/images/talab.png')} />
            </div>
            <p className='is-size-2 has-text-weight-bold'>طلب وشراء</p>
          </div>
          <div className='column'>
            <div className='text-center'>
              <img src={require('../../assets/images/ebdaa.png')} />
            </div>
            <p className='is-size-2 has-text-weight-bold'>استحواذ على الإبداع</p>
          </div>
          <div className='column'>
            <div className='text-center'>
              <img src={require('../../assets/images/basmah.png')} />
            </div>
            <p className='is-size-2 has-text-weight-bold'>بصمة الحواس</p>
          </div>
          <div className='column'>
            <div className='text-center'>
              <img src={require('../../assets/images/ard.png')} />
            </div>
            <p className='is-size-2 has-text-weight-bold'>عرض وبيع</p>
          </div>
        </div>
      </div>
      <div className='container pt-5 pb-5 mt-5 mb-5 has-text-centered'>
        <div className='pt-5 pb-5 mt-5 mb-5'>
          <Link to='/user/signup'>
              <div className='has-text-centered click'>
                <img src={require('../../assets/images/main/writing.png')} width={80} height={80} />
              </div>
          </Link>
          <p className='title has-text-link-dark has-text-weight-bold mt-4'>تسجيل كمبدع</p>
        </div>
      </div>
    </div>
  )
}

export default Partners;
