import React from 'react';
import { useQuery } from 'react-query';
import Header from '../../common/Header';
import server from '../../utilities/server';
import History from './components/History';

const UserJobs = () => {

  const [jobData, setJobData] = React.useState({});

  const [historyModal, setHistoryModal] = React.useState(false);
  const _historyModal = (data) => {
    setJobData(data);
    setHistoryModal(!historyModal);
  }

  const { isLoading, isLoadingError, isError, data, refetch } = useQuery(['jobs'], () => {
    return server({ path: 'jobs', method: 'get' });
  });

  if (isLoading) {
    return <div className='container'>تحميل...</div>
  }

  if (isLoadingError || isError) {
    return <div className='container'>خطأ...</div>
  }

  const Actions = ({ data }) => {
    return (
      <td width='30'>
        <div className='is-pulled-left'>
          <div className='field is-grouped'>
            <div className='control'>
              <button className='button is-text is-small' onClick={() => _historyModal(data)}>متابعة</button>
            </div>
          </div>
        </div>
      </td>
    )
  }

  return (
    <div>
      <History modal={historyModal} setModal={_historyModal} reload={refetch} product={jobData} />
      <Header type='user' />
      <div className='container'>
        <div className='table-container'>
          <table className='table is-striped is-hoverable is-fullwidth'>
            <thead>
              <tr>
                <th width='10'>#</th>
                <th>المنتج</th>
                <th className='has-text-centered'>الإجراء</th>
              </tr>
            </thead>
            <tbody>
              {
                data.data.map((job, index) => {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      <td>{job.product.name} - 
                        <small className='is-size-7'> الحالة: { 
                          job.status === 'open' ? 'فعّال' : 'مغلق'
                        }</small>
                      </td>
                      <Actions data={job} />
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default UserJobs;
